@use "sass:math";

/* Awesome contrast ratio function
* via https://gist.github.com/voxpelli/6304812
**/
@function color_luminance($color) {
  // Adapted from: https://github.com/LeaVerou/contrast-ratio/blob/gh-pages/color.js
  // Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
  $rgba: red($color), green($color), blue($color);
  $rgba2: ();

  @for $i from 1 through 3 {
    $rgb: nth($rgba, $i);
    $rgb: math.div($rgb, 255);

    $rgb: if(
      $rgb < 0.03928,
      math.div($rgb, 12.92),
      math.pow(math.div(($rgb + 0.055), 1.055), 2.4)
    );

    $rgba2: append($rgba2, $rgb);
  }

  @return 0.2126 * nth($rgba2, 1) + 0.7152 * nth($rgba2, 2) + 0.0722 *
    nth($rgba2, 3);
}

@function color_contrast($color1, $color2) {
  // Adapted from: https://github.com/LeaVerou/contrast-ratio/blob/gh-pages/color.js
  // Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
  $luminance1: color_luminance($color1) + 0.05;
  $luminance2: color_luminance($color2) + 0.05;
  $ratio: math.div($luminance1, $luminance2);

  @if ($luminance2 > $luminance1) {
    $ratio: math.div(1, $ratio);
  }

  $ratio: round($ratio * 10) / 10;

  @return $ratio;
}

@function get-contrast-color($color) {
  @if (color_luminance($color) < 0.5) {
    @return #fff;
  } @else {
    @return #000;
  }
}
