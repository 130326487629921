@import "../../node_modules/ckeditor5/dist/ckeditor5.css";

:root {
  --ck-z-panel: 999999999999999999;
  .ck.ck-dialog-overlay {
    z-index: var(--ck-z-panel) !important;
  }
}

html.loginView {
  height: 100%;
}

body.loginView {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  background-color: #fff;
  line-height: 1.5;
  color: var(--color-contrast-dark);
  margin: 0;
  height: 100%;
}

.ecpl-tree-view {
  padding-right: 10px;
  border-right: 1px solid #dedede;
}

.p-datatable a:hover:not(.btn) {
  text-decoration: underline;
}

.tree-node-expanded tree-node-wrapper .f_expanded {
  display: block;
}

.tree-node-expanded tree-node-wrapper .f_collapsed {
  display: none;
}

.tree-node-collapsed tree-node-wrapper .f_collapsed {
  display: block;
}

.tree-node-collapsed tree-node-wrapper .f_expanded {
  display: none;
}

.date-picker-container-conformity{
  width: 150px;
}
.signature-area-conformity {
  width: 390px;
  height: 150px;
}

.saving-loader.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--color-background-opacity);
}

.saving-loader .saving-loader-message {
  margin-top: 20%;
}

body .ui-multiselect .ui-multiselect-label {
  font-weight: normal;
}

body .p-inputtext {
  color: var(--color-contrast-dark);
}

.p-datatable-footer .table-view {
  text-align: left;
}

.widget-box > .wb-content.scroll {
  max-height: 200px;
}

.widget-box .fc-view-container {
  height: 226px;
}

.widget-box .wb-title,
full-calendar .fc-toolbar.fc-header-toolbar {
  height: 38px;
}

/* body .fc-timeline .fc-col-resizer {

    display: none;

} */

body .fc th {
  padding: 0 !important;
}

.radio-marg {
  margin-right: 10px;
}

body .ui-dialog {
  margin: auto;
  top: 49% !important;
  left: 49% !important;
  transform: translateY(-50%) translateX(-50%) !important;
}

body .ui-dialog .ui-dialog-content {
  max-height: calc(100vh - 100px);
  padding: 15px;
}

body .p-datatable .p-datatable-thead > tr > th {
  word-wrap: break-word;
}

.upload-button input {
  position: absolute;
  opacity: 0;
  width: 0;
}

.panel-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--color-contrast-opacity-30);
}

.panel-loader > span {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -68px;
  margin-top: -18px;
}

.panel-loader span.loader-icon {
  width: 36px;
  height: 36px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px;
  background: url("../assets/images/loader.gif") no-repeat 0 0;
  background-size: 100% 100%;
}

.btn span.loader-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 5px;
  background: url("../assets/images/loader.gif") no-repeat 0 0;
  background-size: 100% 100%;
}

.btn.loading {
  z-index: 99999;
  position: relative;
}

body .p-datatable .p-datatable-tbody > tr > td.control-column {
  overflow: visible !important;
}

.modal,
.modal-backdrop {
  z-index: 1010;
}

body .p-datatable .ui-column-filter {
  display: inline-block;
}

.label-mode {
  color: var(--color-background-opacity-30);
}

body .terms-dialog {
  overflow: hidden;
}

body .input-btn-group input.p-inputtext {
  width: calc(100% - 45px) !important;
  /* max-width: calc(380px - 45px); */
}

body .input-btn-group .ui-calendar {
  width: calc(100% - 45px) !important;
  max-width: calc(380px - 45px);
  display: inline-block !important;
}

body .input-btn-group .ui-calendar .p-inputtext {
  width: 100% !important;
  max-width: 100% !important;
}

body .input-btn-group .mydp {
  width: calc(100% - 45px) !important;
  max-width: calc(380px - 45px);
  display: inline-block !important;
  vertical-align: top;
}

body .input-btn-group textarea.p-inputtext {
  width: calc(100% - 45px) !important;
  max-width: calc(380px - 45px);
  display: inline-block !important;
  vertical-align: top;
}

body .p-datatable .p-datatable-tbody > tr > td.overflow-enable {
  overflow: visible !important;
}

.p-datatable .p-datatable-tbody > tr.ui-expanded-row-content > td {
  overflow: visible !important;
}

.hide-header .p-datatable-thead {
  display: none;
}

body .p-datatable .p-datatable-tbody > tr.ui-rowgroup-header > td a:not(.btn) {
  float: right;
}

.bg-light-grey {
  background: var(--color-background-light);
}

/* grid-view */
.grid-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.grid-item {
  width: 50%;
  padding: 10px;
}

.grid-body {
  padding: 20px;
  background-color: var(--color-background-light);
  min-height: 185px;
}

.grid-item-header {
  padding-bottom: 6px;
}

.grid-item-header {
  display: flex;
}

.grid-item-status,
.grid-item-icon,
.grid-item-title {
  display: inline-flex;
  align-items: center;
}

.grid-item-title {
  font-weight: bold;
  padding: 0 5px;
  font-family: "Inter", sans-serif;
}

.grid-item-icon {
  margin-right: 6px;
  opacity: 0.8;
}

.grid-item-status {
  margin-left: auto;
}

.grid-item-status .icon {
  background-color: rgb(231, 100, 79);
  border-radius: 50%;
  text-align: center;
  color: white;
  font-size: 16px;
}

.grid-item-status .icon.icon-checkmark {
  background-color: rgb(78, 197, 92);
}

.overflow-visible,
body .p-datatable tr > td.overflow-visible {
  overflow: visible !important;
}

body #imgPreviewDialog .ui-dialog .ui-dialog-content {
  max-height: calc(100vh - 100px);
}

.mini-tabs {
  margin-bottom: 10px;
}

.tabs-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-item {
  flex: 1;
  padding: 4px 12px;
  color: var(--color-contrast-dark);
  text-align: center;
  border-bottom: 2px solid var(--color-background-dark);
}

.tab-item.active {
  font-weight: bold;
  border-color: currentColor;
}

body .ui-multiselect .ui-multiselect-panel {
  min-width: auto;
  width: 100%;
}

body .ui-multiselect-panel .ui-multiselect-item label {
  white-space: normal;
  width: calc(100% - 20px);
}

.table.no-border > tbody > tr > th,
.table.no-border > tbody > tr > td {
  border: 0;
}

#graphEditor *,
#graphEditor *::after,
#graphEditor *::before {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.fixRowHeight45 > tbody > tr {
  height: 45px;
}

.fixRowHeight58 > tbody > tr {
  height: 58px;
}

.preview-img-popup img {
  max-height: 60vh;
  object-fit: contain;
  max-width: 100%;
}

@media (min-width: 768px) {
  .grid-item {
    width: 33.3333333333%;
    padding: 10px;
  }
}

.notif-content-wrapper .form-group,
.notifi-title {
  margin-bottom: 0;
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  padding: 0;
  max-width: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: var(--color-background-dark);
}

.leave-modal {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10001;
  padding: 15px;
  background-color: var(--color-background-light);
}

.overlay-content {
  position: relative;
  width: 100%;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 40px;
}

.fdv-progress {
  display: flex;
  align-items: center;
}

.fdv-progress > span {
  flex: 1 0 85%;
  margin: 0;
}

.fdv-progress > .progress {
  height: 5px;
}

.fdv-progress > .progress-count {
  flex: 1 0 15%;
  padding: 0 8px;
}

.controls {
  margin: 10px 0px;
  display: flex;
  align-items: center;
}

.controls input[type="range"] {
  width: 80px;
  margin: 0px 5px;
}

.controls button {
  width: 25px;
  height: 25px;
  background-color: #3c78d8;
  color: white;
  border: 0;
  cursor: pointer;
}

.controls button:focus {
  outline: none;
}

#label {
  display: inline-block;
  margin-left: 10px;
}

.spinner-overlay {
  position: absolute;
  z-index: 1002;
  background-color: var(--color-background-opacity-30);
  width: 100%;
  height: 100%;
}

.spinner-wrapper-loader {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-top: 5%;
  margin-left: 10%;
  position: fixed;
  width: 50%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px var(--color-background-opacity-30);
  z-index: 1;
  min-height: 120px;
}

.dropdown-content div {
  color: var(--color-contrast-dark);
  padding: 10px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.dropdown-content {
  z-index: 999999;
}

.form-table > tbody > tr > td {
  padding: 0;
  vertical-align: middle;
}

.form-table > tbody > tr > td > input {
  border: 1px solid transparent;
}

.risk-red-indicator {
  background-color: red;
  width: 25px;
  text-align: center;
}

.risk-yellow-indicator {
  background-color: #ff0;
  width: 25px;
  text-align: center;
}

.risk-green-indicator {
  background-color: green;
  width: 25px;
  text-align: center;
}

.cell-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.form-control.full-wh {
  min-height: 100%;
  min-width: 100%;
}

body .ui-fileupload .ui-fileupload-content {
  padding: 0 !important;
}

.position-relative {
  position: relative;
}

.file-preview-box > iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.text-flame {
  color: #e25822;
}

.SjaRow {
  width: 30%;
}

.sja-use-form-row {
  width: 20%;
}

.parentField {
  font-family: "Inter", sans-serif;
  font-weight: bold;
}

.custom-full-calendar .fc-daygrid-day-events {
  min-height: 0em !important;
}
.custom-full-calendar .fc-scrollgrid-section-body table {
  width: 100% !important;
}
.custom-full-calendar .fc-daygrid-body {
  width: 100% !important;
  /* .ui-table table {
    table-layout: auto !important;
} */
}
.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden !important;
  display: block;
}

a {
  cursor: pointer !important;
  pointer-events: visible !important;
}

span {
  cursor: pointer !important;
  pointer-events: visible !important;
}
