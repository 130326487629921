:root {
  --primary-50: #f9e6f9;
  --primary-100: #ecb8eb;
  --primary-200: #db8cda;
  --primary-300: #c666c5;
  --primary-400: #ae44ac;
  --primary-500: #91268f;
  --primary-600: #7f147d;
  --primary-700: #670765;
  --primary-800: #4a0049;
  --primary-900: #280027;
} /*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #91268f;
  --secondary: #6c757d;
  --success: #1e9b1e;
  --info: #0db2e4;
  --warning: #f09f2e;
  --danger: #e34a31;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Inter", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: var(--color-primary-500);
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
}
a:hover {
  color: var(--color-primary-600);
  text-decoration: none;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  line-height: 1.2;
}
h1,
.h1 {
  font-size: 2.5rem;
}
h2,
.h2 {
  font-size: 2rem;
}
h3,
.h3 {
  font-size: 1.75rem;
}
h4,
.h4 {
  font-size: 1.5rem;
}
h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid var(--color-contrast-opacity-10);
}
small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: var(--color-contrast-light);
}
.blockquote-footer::before {
  content: "— ";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--color-background-light);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: var(--color-contrast-dark);
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid var(--color-background-dark);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid var(--color-background-dark);
}
.table tbody + tbody {
  border-top: 2px solid var(--color-background-dark);
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid var(--color-border);
}
.table-bordered th,
.table-bordered td {
  border: 1px solid var(--color-border);
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: var(--color-background-dark);
}
.table-hover tbody tr:hover {
  color: var(--color-contrast-dark);
  background-color: var(--color-contrast-opacity-50);
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: var(--color-primary-50);
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: var(--color-primary-600);
}
.table-hover .table-primary:hover {
  background-color: var(--color-primary-100);
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: var(--color-primary-100);
}
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: var(--color-background-dark);
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: var(--color-border);
}
.table-hover .table-secondary:hover {
  background-color: var(--color-background-dark);
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: var(--color-background-dark);
}
.table-success,
.table-success > th,
.table-success > td {
  background-color: #c0e3c0;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8acb8a;
}
.table-hover .table-success:hover {
  background-color: #aedbae;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #aedbae;
}
.table-info,
.table-info > th,
.table-info > td {
  background-color: #bbe9f7;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #81d7f1;
}
.table-hover .table-info:hover {
  background-color: #a4e2f4;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a4e2f4;
}
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbe4c4;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f7cd92;
}
.table-hover .table-warning:hover {
  background-color: #f9d9ac;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f9d9ac;
}
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7ccc5;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f0a194;
}
.table-hover .table-danger:hover {
  background-color: #f4b8af;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f4b8af;
}
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: var(--color-background-dark);
  border-color: var(--color-border);
}
.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.7856rem + 2px);
  padding: 0.3928rem 0.8571rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-contrast-dark);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}
.form-control:focus {
  color: var(--color-contrast-dark);
  background-color: #fff;
  border-color: var(--color-primary-500);
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--color-primary-opacity-20);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: var(--color-background-dark);
  opacity: 1;
}
input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}
select.form-control:-moz-focusring {
  color: var(--color-contrast-dark);
  text-shadow: 0 0 0 var(--color-contrast-dark);
}
select.form-control:focus::-ms-value {
  color: var(--color-contrast-dark);
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.3928rem + 1px);
  padding-bottom: calc(0.3928rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.3928rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--color-contrast-dark);
  background-color: rgba(0, 0, 0, 0);
  border: solid var(--color-border);
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[size],
select.form-control[multiple] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: var(--color-contrast-light);
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #1e9b1e;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(30, 155, 30, 0.9);
  border-radius: 4px;
}
.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #1e9b1e;
  padding-right: calc(1.5em + 0.7856rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231e9b1e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1964rem) center;
  background-size: calc(0.75em + 0.3928rem) calc(0.75em + 0.3928rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #1e9b1e;
  box-shadow: 0 0 0 0.2rem rgba(30, 155, 30, 0.25);
}
.was-validated select.form-control:valid,
select.form-control.is-valid {
  padding-right: 3.4284rem !important;
  background-position: right 1.7142rem center;
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.7856rem);
  background-position: top calc(0.375em + 0.1964rem) right
    calc(0.375em + 0.1964rem);
}
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #1e9b1e;
  padding-right: calc(0.75em + 2.4463rem) !important;
  background:
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.8571rem center/8px 10px no-repeat,
    #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231e9b1e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      center right 1.8571rem / calc(0.75em + 0.3928rem) calc(0.75em + 0.3928rem)
      no-repeat;
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #1e9b1e;
  box-shadow: 0 0 0 0.2rem rgba(30, 155, 30, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #1e9b1e;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #1e9b1e;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #1e9b1e;
}
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #26c626;
  background-color: #26c626;
}
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(30, 155, 30, 0.25);
}
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #1e9b1e;
}
.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #1e9b1e;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #1e9b1e;
  box-shadow: 0 0 0 0.2rem rgba(30, 155, 30, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e34a31;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(227, 74, 49, 0.9);
  border-radius: 4px;
}
.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #e34a31;
  padding-right: calc(1.5em + 0.7856rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e34a31' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e34a31' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1964rem) center;
  background-size: calc(0.75em + 0.3928rem) calc(0.75em + 0.3928rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #e34a31;
  box-shadow: 0 0 0 0.2rem rgba(227, 74, 49, 0.25);
}
.was-validated select.form-control:invalid,
select.form-control.is-invalid {
  padding-right: 3.4284rem !important;
  background-position: right 1.7142rem center;
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.7856rem);
  background-position: top calc(0.375em + 0.1964rem) right
    calc(0.375em + 0.1964rem);
}
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e34a31;
  padding-right: calc(0.75em + 2.4463rem) !important;
  background:
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.8571rem center/8px 10px no-repeat,
    #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e34a31' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e34a31' stroke='none'/%3e%3c/svg%3e")
      center right 1.8571rem / calc(0.75em + 0.3928rem) calc(0.75em + 0.3928rem)
      no-repeat;
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #e34a31;
  box-shadow: 0 0 0 0.2rem rgba(227, 74, 49, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #e34a31;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #e34a31;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e34a31;
}
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e9715e;
  background-color: #e9715e;
}
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(227, 74, 49, 0.25);
}
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #e34a31;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e34a31;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e34a31;
  box-shadow: 0 0 0 0.2rem rgba(227, 74, 49, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 0.3928rem 0.8571rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: var(--color-primary-500);
  border-color: var(--color-primary-500);
}
.btn-primary:hover {
  color: #fff;
  background-color: var(--color-primary-400);
  border-color: var(--color-primary-400);
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: var(--color-primary-400);
  border-color: var(--color-primary-400);
  box-shadow: 0 0 0 0.2rem var(--color-primary-opacity-40);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--color-primary-opacity-30);
  border-color: var(--color-primary-opacity-30);
}
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--color-background-light);
  border-color: var(--color-primary-500);
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--color-primary-opacity-50);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #1e9b1e;
  border-color: #1e9b1e;
}
.btn-success:hover {
  color: #fff;
  background-color: #187b18;
  border-color: #167016;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #187b18;
  border-color: #167016;
  box-shadow: 0 0 0 0.2rem rgba(64, 170, 64, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #1e9b1e;
  border-color: #1e9b1e;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #167016;
  border-color: #146614;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 170, 64, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #0db2e4;
  border-color: #0db2e4;
}
.btn-info:hover {
  color: #fff;
  background-color: #0b96c0;
  border-color: #0a8cb4;
}
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #0b96c0;
  border-color: #0a8cb4;
  box-shadow: 0 0 0 0.2rem rgba(49, 190, 232, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #0db2e4;
  border-color: #0db2e4;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0a8cb4;
  border-color: #0a83a8;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 190, 232, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #f09f2e;
  border-color: #f09f2e;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e78e11;
  border-color: #db8610;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #e78e11;
  border-color: #db8610;
  box-shadow: 0 0 0 0.2rem rgba(209, 141, 45, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #f09f2e;
  border-color: #f09f2e;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #db8610;
  border-color: #cf7f0f;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 141, 45, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #e34a31;
  border-color: #e34a31;
}
.btn-danger:hover {
  color: #fff;
  background-color: #d1361c;
  border-color: #c6331b;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #d1361c;
  border-color: #c6331b;
  box-shadow: 0 0 0 0.2rem rgba(231, 101, 80, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #e34a31;
  border-color: #e34a31;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c6331b;
  border-color: #bb3019;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 101, 80, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-outline-primary {
  color: var(--color-primary-500);
  border-color: var(--color-primary-500);
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--color-primary-400);
  border-color: var(--color-primary-400);
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem var(--color-primary-opacity-50);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--color-primary-500);
  background-color: rgba(0, 0, 0, 0);
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--color-primary-500);
  border-color: var(--color-primary-500);
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--color-primary-opacity-50);
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: rgba(0, 0, 0, 0);
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #1e9b1e;
  border-color: #1e9b1e;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #1e9b1e;
  border-color: #1e9b1e;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 155, 30, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #1e9b1e;
  background-color: rgba(0, 0, 0, 0);
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #1e9b1e;
  border-color: #1e9b1e;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 155, 30, 0.5);
}
.btn-outline-info {
  color: #0db2e4;
  border-color: #0db2e4;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #0db2e4;
  border-color: #0db2e4;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 178, 228, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #0db2e4;
  background-color: rgba(0, 0, 0, 0);
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0db2e4;
  border-color: #0db2e4;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 178, 228, 0.5);
}
.btn-outline-warning {
  color: #f09f2e;
  border-color: #f09f2e;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #f09f2e;
  border-color: #f09f2e;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 159, 46, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #f09f2e;
  background-color: rgba(0, 0, 0, 0);
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #f09f2e;
  border-color: #f09f2e;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 159, 46, 0.5);
}
.btn-outline-danger {
  color: #e34a31;
  border-color: #e34a31;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #e34a31;
  border-color: #e34a31;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 74, 49, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #e34a31;
  background-color: rgba(0, 0, 0, 0);
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e34a31;
  border-color: #e34a31;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 74, 49, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: rgba(0, 0, 0, 0);
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: rgba(0, 0, 0, 0);
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: var(--color-primary-500);
  text-decoration: none;
}
.btn-link:hover {
  color: var(--color-primary-500);
  text-decoration: none;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid rgba(0, 0, 0, 0);
  border-bottom: 0;
  border-left: 0.3em solid rgba(0, 0, 0, 0);
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--color-contrast-opacity-20);
  border-radius: 4px;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid rgba(0, 0, 0, 0);
  border-bottom: 0.3em solid;
  border-left: 0.3em solid rgba(0, 0, 0, 0);
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid rgba(0, 0, 0, 0);
  border-right: 0;
  border-bottom: 0.3em solid rgba(0, 0, 0, 0);
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid rgba(0, 0, 0, 0);
  border-right: 0.3em solid;
  border-bottom: 0.3em solid rgba(0, 0, 0, 0);
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--color-border);
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.3928rem 0.8571rem;
  clear: both;
  font-weight: 400;
  color: var(--color-contrast-dark);
  text-align: inherit;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: var(--color-background-dark);
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--color-primary-500);
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 0.8571rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.3928rem 0.8571rem;
  color: #212529;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.642825rem;
  padding-left: 0.642825rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation)
  > .custom-file:not(:last-child)
  .custom-file-label,
.input-group:not(.has-validation)
  > .custom-file:not(:last-child)
  .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation
  > .custom-file:nth-last-child(n + 3)
  .custom-file-label,
.input-group.has-validation
  > .custom-file:nth-last-child(n + 3)
  .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.3928rem 0.8571rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: var(--color-background-dark);
  border: 1px solid var(--color-border);
  border-radius: 4px;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}
.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}
.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}
.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.8571rem;
}
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation)
  > .input-group-append:not(:last-child)
  > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation
  > .input-group-append:nth-last-child(n + 3)
  > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--color-primary-600);
  background-color: var(--color-primary-600);
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--color-primary-opacity-20);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--color-primary-500);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: var(--color-primary-500);
  border-color: var(--color-primary-500);
}
.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: var(--color-background-dark);
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 4px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: var(--color-primary-500);
  background-color: var(--color-primary-500);
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: var(--color-primary-opacity-500);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: var(--color-primary-opacity-500);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: var(--color-primary-opdacity-500);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: var(--color-background-dark);
  border-radius: 0.5rem;
  transition:
    transform 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: var(--color-primary-opdacity-500);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.7856rem + 2px);
  padding: 0.3928rem 1.8571rem 0.3928rem 0.8571rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 0.8571rem center/8px 10px no-repeat;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  appearance: none;
}
.custom-select:focus {
  border-color: var(--color-primary-500);
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--color-primary-opacity-20);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.8571rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: var(--color-background-dark);
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 0 #495057;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.7856rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.7856rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: var(--color-primary-500);
  box-shadow: 0 0 0 0.2rem var(--color-primary-opacity-20);
}
.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: var(--color-background-dark);
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.7856rem + 2px);
  padding: 0.3928rem 0.8571rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-contrast-dark);
  background-color: #fff;
  border: 1px solid var(--color-border);
  border-radius: 4px;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.7856rem);
  padding: 0.3928rem 0.8571rem;
  line-height: 1.5;
  color: var(--color-contrast-dark);
  content: "Browse";
  background-color: var(--color-background-dark);
  border-left: inherit;
  border-radius: 0 4px 4px 0;
}
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow:
    0 0 0 1px #fff,
    0 0 0 0.2rem var(--color-primary-opacity-20);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow:
    0 0 0 1px #fff,
    0 0 0 0.2rem var(--color-primary-opacity-20);
}
.custom-range:focus::-ms-thumb {
  box-shadow:
    0 0 0 1px #fff,
    0 0 0 0.2rem var(--color-primary-opacity-20);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: var(--color-primary-500);
  border: 0;
  border-radius: 1rem;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: var(--color-primary-500);
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: var(--color-contrast-dark);
  cursor: pointer;
  background-color: var(--color-background-dark);
  border-color: var(--color-border);
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: var(--color-primary-500);
  border: 0;
  border-radius: 1rem;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: var(--color-primary-500);
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: var(--color-contrast-dark);
  cursor: pointer;
  background-color: var(--color-background-dark);
  border-color: var(--color-border);
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: var(--color-primary-500);
  border: 0;
  border-radius: 1rem;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: var(--color-primary-500);
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: var(--color-contrast-dark);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: var(--color-background-light);
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: var(--color-background-light);
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: var(--color-background-dark);
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: var(--color-background-dark);
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: var(--color-background-dark);
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: var(--color-contrast-light);
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid var(--color-background-dark);
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--color-background-dark) var(--color-background-dark)
    var(--color-background-dark);
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: var(--color-background-dark) var(--color-background-dark) #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 4px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--color-primary-500);
}
.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid var(--color-border);
  border-radius: 8px;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 7px 7px 0 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 7px 7px;
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 7px;
}
.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 4px;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--color-primary-500);
  background-color: #fff;
  border: 1px solid var(--color-border);
}
.page-link:hover {
  z-index: 2;
  color: var(--color-primary-500);
  text-decoration: none;
  background-color: var(--color-background-light);
  border-color: var(--color-background-light);
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--color-primary-opacity-20);
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--color-primary-500);
  border-color: var(--color-primary-500);
}
.page-item.disabled .page-link {
  color: var(--color-contrast-light);
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: var(--color-background-light);
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover,
a.badge:focus {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: var(--color-primary-500);
}
a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: var(--color-primary-500);
}
a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--color-primary-opacity-50);
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #1e9b1e;
}
a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #167016;
}
a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(30, 155, 30, 0.5);
}
.badge-info {
  color: #fff;
  background-color: #0db2e4;
}
a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #0a8cb4;
}
a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 178, 228, 0.5);
}
.badge-warning {
  color: #212529;
  background-color: #f09f2e;
}
a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #db8610;
}
a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(240, 159, 46, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #e34a31;
}
a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #c6331b;
}
a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(227, 74, 49, 0.5);
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: var(--color-background-dark);
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #4b144a;
  background-color: #e9d4e9;
  border-color: #e0c2e0;
}
.alert-primary hr {
  border-top-color: #d7b1d7;
}
.alert-primary .alert-link {
  color: #230922;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #105110;
  background-color: #d2ebd2;
  border-color: #c0e3c0;
}
.alert-success hr {
  border-top-color: #aedbae;
}
.alert-success .alert-link {
  color: #082608;
}
.alert-info {
  color: #075d77;
  background-color: #cff0fa;
  border-color: #bbe9f7;
}
.alert-info hr {
  border-top-color: #a4e2f4;
}
.alert-info .alert-link {
  color: #043747;
}
.alert-warning {
  color: #7d5318;
  background-color: #fcecd5;
  border-color: #fbe4c4;
}
.alert-warning hr {
  border-top-color: #f9d9ac;
}
.alert-warning .alert-link {
  color: #523710;
}
.alert-danger {
  color: #762619;
  background-color: #f9dbd6;
  border-color: #f7ccc5;
}
.alert-danger hr {
  border-top-color: #f4b8af;
}
.alert-danger .alert-link {
  color: #4c1810;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: var(--color-background-dark);
  border-radius: 4px;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: var(--color-primary-500);
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 4px;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: var(--color-background-dark);
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: var(--color-primary-500);
  border-color: var(--color-primary-500);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #4b144a;
  background-color: #e0c2e0;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #4b144a;
  background-color: #d7b1d7;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #4b144a;
  border-color: #4b144a;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #105110;
  background-color: #c0e3c0;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #105110;
  background-color: #aedbae;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #105110;
  border-color: #105110;
}
.list-group-item-info {
  color: #075d77;
  background-color: #bbe9f7;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #075d77;
  background-color: #a4e2f4;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #075d77;
  border-color: #075d77;
}
.list-group-item-warning {
  color: #7d5318;
  background-color: #fbe4c4;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #7d5318;
  background-color: #f9d9ac;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7d5318;
  border-color: #7d5318;
}
.list-group-item-danger {
  color: #762619;
  background-color: #f7ccc5;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #762619;
  background-color: #f4b8af;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #762619;
  border-color: #762619;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid var(--color-background-dark);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid var(--color-background-dark);
  border-radius: 8px;
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 60vw;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.select-manual-dropdown {
  width: 100%;
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
}
.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #91268f !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #691b67 !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #1e9b1e !important;
}
a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #167016 !important;
}
.bg-info {
  background-color: #0db2e4 !important;
}
a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0a8cb4 !important;
}
.bg-warning {
  background-color: #f09f2e !important;
}
a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #db8610 !important;
}
.bg-danger {
  background-color: #e34a31 !important;
}
a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c6331b !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: rgba(0, 0, 0, 0) !important;
}
.border {
  border: 1px solid var(--color-background-dark) !important;
}
.border-top {
  border-top: 1px solid var(--color-background-dark) !important;
}
.border-right {
  border-right: 1px solid var(--color-background-dark) !important;
}
.border-bottom {
  border-bottom: 1px solid var(--color-background-dark) !important;
}
.border-left {
  border-left: 1px solid var(--color-background-dark) !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #91268f !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #1e9b1e !important;
}
.border-info {
  border-color: #0db2e4 !important;
}
.border-warning {
  border-color: #f09f2e !important;
}
.border-danger {
  border-color: #e34a31 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 4px !important;
}
.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.rounded-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}
.user-select-auto {
  user-select: auto !important;
}
.user-select-none {
  user-select: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: var(--color-primary-500) !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: var(--color-primary-600) !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}
.text-success {
  color: #1e9b1e !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #125b12 !important;
}
.text-info {
  color: #0db2e4 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #09799c !important;
}
.text-warning {
  color: #f09f2e !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #c3780e !important;
}
.text-danger {
  color: #e34a31 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #b02d18 !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: rgba(0, 0, 0, 0);
  text-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid var(--color-border) !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: var(--color-border);
  }
  .table .thead-dark th {
    color: inherit;
    border-color: var(--color-border);
  }
}
:root {
  --text-color: $gray-900;
  --text-color-secondary: $gray-600;
  --primary-color: $primary;
  --primary-color-text: #ffffff;
  --maskbg: rgba($black, 0.4);
  --focus-ring: 0 0 0 0.2rem rgba($primary, 0.1);
  color-scheme: light;
}
.p-component {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: normal;
}
.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.15s;
}
.p-disabled,
.p-component:disabled {
  opacity: 0.65;
}
.p-error {
  color: #e34a31;
}
.p-text-secondary {
  color: #6c757d;
}
.pi {
  font-size: 1rem;
}
.p-link {
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  border-radius: 4px;
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}
.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}
@keyframes p-component-overlay-enter-animation {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}
.p-autocomplete .p-autocomplete-loader {
  right: 0.75rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 3.107rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.25rem 0.75rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
  border-color: var(--color-primary-500);
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.25rem 0;
}
.p-autocomplete
  .p-autocomplete-multiple-container
  .p-autocomplete-input-token
  input {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: #212529;
  padding: 0;
  margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: var(--color-primary-500);
  color: #fff;
  border-radius: 4px;
}
.p-autocomplete
  .p-autocomplete-multiple-container
  .p-autocomplete-token
  .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}
p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #e34a31;
}
.p-autocomplete-panel {
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}
.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.5rem 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #212529;
  background: var(--color-background-light);
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #fff;
  background: var(--color-primary-500);
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: rgba(0, 0, 0, 0);
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: var(--color-background-light);
  font-weight: 600;
}
p-autocomplete.p-autocomplete-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
  color: #495057;
  right: 0.75rem;
}
p-autocomplete.p-autocomplete-clearable
  .p-autocomplete-dd
  .p-autocomplete-clear-icon {
  color: #495057;
  right: 3.107rem;
}
p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #e34a31;
}
.p-datepicker {
  padding: 0;
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: var(--color-background-light);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #efefef;
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #212529;
  background: var(--color-background-light);
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid var(--color-background-dark);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #212529;
  transition: box-shadow 0.15s;
  font-weight: 600;
  padding: 0.5rem;
}
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-year:enabled:hover,
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month:enabled:hover {
  color: var(--color-primary-500);
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
  text-align: center;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 4px;
  transition: box-shadow 0.15s;
  border: 1px solid rgba(0, 0, 0, 0);
}
.p-datepicker table td > span.p-highlight {
  color: #fff;
  background: var(--color-primary-500);
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-datepicker table td.p-datepicker-today > span {
  background: #ced4da;
  color: #212529;
  border-color: rgba(0, 0, 0, 0);
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #fff;
  background: var(--color-primary-500);
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid var(--color-background-dark);
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid var(--color-background-dark);
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #fff;
  background: var(--color-primary-500);
}
.p-datepicker .p-yearpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.5rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: #fff;
  background: var(--color-primary-500);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid var(--color-background-dark);
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
  border-left: 0 none;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
}
.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):hover {
  background: var(--color-background-light);
}
.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: var(--color-background-light);
}
.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-datepicker:not(.p-disabled)
  .p-yearpicker
  .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: var(--color-background-light);
}
.p-datepicker:not(.p-disabled)
  .p-yearpicker
  .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
p-calendar.p-calendar-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  color: #495057;
  right: 0.75rem;
}
p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon {
  color: #495057;
  right: 3.107rem;
}
@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: var(--color-background-light);
  border: 1px solid #ced4da;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  border-radius: 4px;
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
  border-color: var(--color-primary-500);
}
.p-cascadeselect .p-cascadeselect-label {
  background: rgba(0, 0, 0, 0);
  border: 0 none;
  padding: 0.5rem 0.75rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: #6c757d;
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
  background: rgba(0, 0, 0, 0);
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-cascadeselect-panel {
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}
.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.5rem 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item
  .p-cascadeselect-item-content {
  padding: 0.5rem 1.5rem;
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item
  .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item.p-highlight {
  color: #fff;
  background: var(--color-primary-500);
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: var(--color-background-light);
}
.p-cascadeselect-panel
  .p-cascadeselect-items
  .p-cascadeselect-item
  .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}
p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
  border-color: #e34a31;
}
.p-input-filled .p-cascadeselect {
  background: #efefef;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #efefef;
}
p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
  padding-right: 0.75rem;
}
p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
  color: #495057;
  right: 2.357rem;
}
.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: var(--color-background-light);
  width: 20px;
  height: 20px;
  color: #212529;
  border-radius: 4px;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.15s;
  color: #fff;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--color-primary-500);
  background: var(--color-primary-500);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #ced4da;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
  border-color: var(--color-primary-500);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #691b67;
  background: #691b67;
  color: #fff;
}
p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #e34a31;
}
.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #efefef;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--color-primary-500);
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #efefef;
}
.p-input-filled
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  background: #691b67;
}
.p-checkbox-label {
  margin-left: 0.5rem;
  margin-bottom: 0;
}
.p-highlight .p-checkbox .p-checkbox-box {
  border-color: #fff;
}
.p-chips .p-chips-multiple-container {
  padding: 0.25rem 0.75rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
  border-color: var(--color-primary-500);
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: var(--color-primary-500);
  color: #fff;
  border-radius: 4px;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.25rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: #212529;
  padding: 0;
  margin: 0;
}
p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #e34a31;
}
p-chips.p-chips-clearable .p-inputtext {
  padding-right: 1.75rem;
}
p-chips.p-chips-clearable .p-chips-clear-icon {
  color: #495057;
  right: 0.75rem;
}
.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
  width: 2rem;
  height: 2rem;
}
.p-colorpicker-panel {
  background: #212529;
  border: 1px solid #212529;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #fff;
}
.p-colorpicker-overlay-panel {
  box-shadow: none;
}
.p-dropdown {
  background: var(--color-background-light);
  border: 1px solid #ced4da;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  border-radius: 4px;
  width: 100%;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
  border-color: var(--color-primary-500);
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}
.p-dropdown .p-dropdown-label {
  background: rgba(0, 0, 0, 0);
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: rgba(0, 0, 0, 0);
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #495057;
  right: 2.357rem;
}
.p-dropdown-panel {
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem;
  border-bottom: 1px solid var(--color-background-dark);
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.5rem 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
  border-radius: 0;
  white-space: normal;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #fff;
  background: var(--color-primary-500);
}
.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: var(--color-background-light);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: rgba(0, 0, 0, 0);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: var(--color-background-light);
  font-weight: 600;
}
p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #e34a31;
}
.p-input-filled .p-dropdown {
  background: #efefef;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #efefef;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
  background-color: rgba(0, 0, 0, 0);
}
.p-editor-container .p-editor-toolbar {
  background: #efefef;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid var(--color-border);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker
  .ql-picker-label:hover {
  color: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker
  .ql-picker-label:hover
  .ql-stroke {
  stroke: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker
  .ql-picker-label:hover
  .ql-fill {
  fill: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label {
  color: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label
  .ql-stroke {
  stroke: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label
  .ql-fill {
  fill: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options {
  background: var(--color-background-light);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 4px;
  padding: 0.5rem 0;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item {
  color: #212529;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item:hover {
  color: #212529;
  background: var(--color-background-light);
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded:not(.ql-icon-picker)
  .ql-picker-item {
  padding: 0.5rem 1.5rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid var(--color-border);
}
.p-editor-container .p-editor-content .ql-editor {
  background: var(--color-background-light);
  color: #495057;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #212529;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #212529;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #212529;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: var(--color-primary-500);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #91268f;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #91268f;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container
  .ql-snow.ql-toolbar
  .ql-picker-label.ql-active
  .ql-picker-label,
.p-editor-container
  .ql-snow.ql-toolbar
  .ql-picker-item.ql-selected
  .ql-picker-label {
  color: var(--color-primary-500);
}
.p-inputgroup-addon {
  background: var(--color-background-light);
  color: #495057;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 0.5rem 0.75rem;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: 1px solid #ced4da;
}
.p-inputgroup > .p-component,
.p-inputgroup > .p-element,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-element + .p-inputgroup-addon,
.p-inputgroup
  > .p-inputwrapper
  > .p-component
  > .p-inputtext
  + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-element:focus,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-element:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}
.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}
p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #e34a31;
}
p-inputmask.p-inputmask-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
  color: #495057;
  right: 0.75rem;
}
p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #e34a31;
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
  padding-right: 2.5rem;
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
  color: #495057;
  right: 0.75rem;
}
p-inputnumber.p-inputnumber-clearable
  .p-inputnumber-buttons-stacked
  .p-inputnumber-clear-icon {
  right: 3.107rem;
}
p-inputnumber.p-inputnumber-clearable
  .p-inputnumber-buttons-horizontal
  .p-inputnumber-clear-icon {
  right: 3.107rem;
}
.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  border-radius: 1rem;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: var(--color-background-light);
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 1rem;
  transition-duration: 0.15s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #ced4da;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--color-primary-500);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: var(--color-background-light);
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background: var(--color-primary-500);
}
p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #e34a31;
}
.p-inputtext,
.ui-inputtext {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: #495057;
  background: var(--color-background-light);
  padding: 0.3928rem 0.8571rem;
  border: 1px solid #ced4da;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  appearance: none;
  border-radius: 4px;
  width: 100%;
}
.p-inputtext:enabled:hover,
.ui-inputtext:enabled:hover {
  border-color: #ced4da;
}
.p-inputtext:enabled:focus,
.ui-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
  border-color: var(--color-primary-500);
}
.p-inputtext.ng-dirty.ng-invalid,
.ui-inputtext.ng-dirty.ng-invalid {
  border-color: #e34a31;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}
.p-float-label > label {
  left: 0.75rem;
  color: #6c757d;
  transition-duration: 0.15s;
}
.p-float-label > .ng-invalid.ng-dirty + label {
  color: #e34a31;
}
.p-input-icon-left > i:first-of-type {
  left: 0.75rem;
  color: #495057;
}
.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}
.p-input-icon-left.p-float-label > label {
  left: 2.5rem;
}
.p-input-icon-right > i:last-of-type {
  right: 0.75rem;
  color: #495057;
}
.p-input-icon-right > .p-inputtext {
  padding-right: 2.5rem;
}
::-webkit-input-placeholder {
  color: #6c757d;
}
:-moz-placeholder {
  color: #6c757d;
}
::-moz-placeholder {
  color: #6c757d;
}
:-ms-input-placeholder {
  color: #6c757d;
}
.p-input-filled .p-inputtext {
  background-color: #efefef;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #efefef;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: #efefef;
}
.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}
.p-listbox {
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.p-listbox .p-listbox-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid var(--color-background-dark);
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.75rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list {
  padding: 0.5rem 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #fff;
  background: var(--color-primary-500);
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: var(--color-background-light);
  font-weight: 600;
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: rgba(0, 0, 0, 0);
}
.p-listbox:not(.p-disabled)
  .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: var(--color-background-light);
}
p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #e34a31;
}
.p-multiselect {
  background: var(--color-background-light);
  border: 1px solid #ced4da;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  border-radius: 4px;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
  border-color: var(--color-primary-500);
}
.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.75rem;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: var(--color-primary-500);
  color: #fff;
  border-radius: 4px;
}
.p-multiselect.p-multiselect-chip
  .p-multiselect-token
  .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: rgba(0, 0, 0, 0);
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.75rem;
}
.p-multiselect-panel {
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.16);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem;
  border-bottom: 1px solid var(--color-background-dark);
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext {
  padding-right: 1.75rem;
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-multiselect-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #fff;
  background: var(--color-primary-500);
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: var(--color-background-light);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: var(--color-background-light);
  font-weight: 600;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: rgba(0, 0, 0, 0);
}
p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #e34a31;
}
.p-input-filled .p-multiselect {
  background: #efefef;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #efefef;
}
p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
  padding-right: 1.75rem;
}
p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
  color: #495057;
  right: 2.357rem;
}
p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
  border-color: #e34a31;
}
.p-password-panel {
  padding: 1.25rem;
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  border-radius: 4px;
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: var(--color-background-light);
}
.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #e34a31;
}
.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #ffc107;
}
.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #28a745;
}
p-password.p-password-clearable .p-password-input {
  padding-right: 2.5rem;
}
p-password.p-password-clearable .p-password-clear-icon {
  color: #495057;
  right: 0.75rem;
}
p-password.p-password-clearable.p-password-mask .p-password-input {
  padding-right: 4.25rem;
}
p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
  color: #495057;
  right: 2.5rem;
}
.p-radiobutton {
  width: 20px;
  height: 20px;
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid #adb5bd;
  background: var(--color-background-light);
  width: 20px;
  height: 20px;
  color: #212529;
  border-radius: 50%;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--color-contrast-opacity-50);
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--color-primary-opacity-10);
  border-color: var(--color-primary-500);
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.15s;
  background-color: #fff;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--color-primary-500);
  background: var(--color-background-dark);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--color-primary-400);
  background: var(--color-background-dark);
  color: #fff;
}
p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #e34a31;
}
.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #efefef;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--color-primary-500);
}
.p-input-filled
  .p-radiobutton
  .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #691b67;
}
.p-radiobutton-label {
  margin-left: 0.5rem;
  margin-bottom: 0;
}
.p-highlight .p-radiobutton .p-radiobutton-box {
  border-color: #fff;
}
.p-rating .p-rating-icon {
  color: #495057;
  margin-left: 0.5rem;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: #e34a31;
}
.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.pi-star-fill {
  color: var(--color-primary-500);
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: var(--color-primary-500);
}
.p-rating:not(.p-disabled):not(.p-readonly)
  .p-rating-icon.p-rating-cancel:hover {
  color: #e34a31;
}
.p-highlight .p-rating .p-rating-icon {
  color: #fff;
}
.ui-spinner .ui-spinner-input {
  padding-right: 2.429em;
}
.ui-spinner .ui-spinner-button {
  width: 2em;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  color: #848484;
}
.ui-spinner .ui-spinner-button.ui-spinner-up {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
.ui-spinner .ui-spinner-button.ui-spinner-down {
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.ui-spinner .ui-spinner-button .ui-spinner-button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
}
.p-selectbutton .p-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #fff;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #fff;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #5a6268;
  border-color: #545b62;
  color: #fff;
}
.p-selectbutton
  .p-button:not(.p-disabled):not(.p-highlight):hover
  .p-button-icon-left,
.p-selectbutton
  .p-button:not(.p-disabled):not(.p-highlight):hover
  .p-button-icon-right {
  color: #fff;
}
.p-selectbutton .p-button.p-highlight {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #fff;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #fff;
}
p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #e34a31;
}
.p-slider {
  background: var(--color-background-light);
  border: 0 none;
  border-radius: 4px;
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: var(--color-primary-500);
  border: 2px solid #91268f;
  border-radius: 4px;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-slider .p-slider-range {
  background: var(--color-primary-500);
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #691b67;
  border-color: #691b67;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s,
    left 0.15s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.15s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s,
    bottom 0.15s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.15s;
}
.p-togglebutton.p-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #fff;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #fff;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #5a6268;
  border-color: #545b62;
  color: #fff;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
  .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
  .p-button-icon-right {
  color: #fff;
}
.p-togglebutton.p-button.p-highlight {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #fff;
}
.p-togglebutton.p-button.p-highlight:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #fff;
}
p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #e34a31;
}
.p-treeselect {
  background: var(--color-background-light);
  border: 1px solid #ced4da;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  border-radius: 4px;
}
.p-treeselect:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
  border-color: var(--color-primary-500);
}
.p-treeselect .p-treeselect-label {
  padding: 0.5rem 0.75rem;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
}
.p-treeselect .p-treeselect-label.p-placeholder {
  color: #6c757d;
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: var(--color-background-dark);
  color: #212529;
  border-radius: 16px;
}
.p-treeselect .p-treeselect-trigger {
  background: rgba(0, 0, 0, 0);
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
  border-color: #e34a31;
}
.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.25rem 0.75rem;
}
.p-treeselect-panel {
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}
.p-treeselect-panel .p-treeselect-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid var(--color-background-dark);
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: 0.5rem;
}
.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container
  .p-treeselect-filter {
  padding-right: 1.75rem;
}
.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container
  .p-treeselect-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container.p-treeselect-clearable-filter
  .p-treeselect-filter {
  padding-right: 3.5rem;
}
.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container.p-treeselect-clearable-filter
  .p-treeselect-filter-clear-icon {
  right: 2.5rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: rgba(0, 0, 0, 0);
}
.p-input-filled .p-treeselect {
  background: #efefef;
}
.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #efefef;
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: #efefef;
}
p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
  padding-right: 1.75rem;
}
p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
  color: #495057;
  right: 2.357rem;
}
.p-button {
  color: #fff;
  background: var(--color-primary-500);
  border: 1px solid #91268f;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  border-radius: 4px;
}
.p-button:enabled:hover {
  background: #691b67;
  color: #fff;
  border-color: #691b67;
}
.p-button:enabled:active {
  background: #691b67;
  color: #fff;
  border-color: #691b67;
}
.p-button.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-primary-500);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(0, 123, 255, 0.04);
  color: var(--color-primary-500);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(0, 123, 255, 0.16);
  color: var(--color-primary-500);
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: var(--color-background-light);
  color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: var(--color-background-dark);
  color: #6c757d;
}
.p-button.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-primary-500);
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-text:enabled:hover {
  background: rgba(0, 123, 255, 0.04);
  color: var(--color-primary-500);
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-text:enabled:active {
  background: rgba(0, 123, 255, 0.16);
  color: var(--color-primary-500);
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: var(--color-background-light);
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: var(--color-background-dark);
  color: #6c757d;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: var(--color-primary-500);
  background-color: #fff;
}
.p-button.p-button-raised {
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0.5rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}
.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}
.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 2.357rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}
.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: #fff;
  background: #6c757d;
  border: 1px solid #6c757d;
}
.p-button.p-button-secondary:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #5a6268;
  color: #fff;
  border-color: #5a6268;
}
.p-button.p-button-secondary:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.p-button.p-button-secondary:enabled:active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #545b62;
  color: #fff;
  border-color: #4e555b;
}
.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: #6c757d;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(108, 117, 125, 0.04);
  color: #6c757d;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(108, 117, 125, 0.16);
  color: #6c757d;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: #6c757d;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(108, 117, 125, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #6c757d;
}
.p-button.p-button-secondary.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(108, 117, 125, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #6c757d;
}
.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  color: #fff;
  background: #17a2b8;
  border: 1px solid #17a2b8;
}
.p-button.p-button-info:enabled:hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #138496;
  color: #fff;
  border-color: #117a8b;
}
.p-button.p-button-info:enabled:focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.p-button.p-button-info:enabled:active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #138496;
  color: #fff;
  border-color: #117a8b;
}
.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(23, 162, 184, 0.04);
  color: #17a2b8;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(23, 162, 184, 0.16);
  color: #17a2b8;
  border: 1px solid;
}
.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-info.p-button-text:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(23, 162, 184, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
}
.p-button.p-button-info.p-button-text:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(23, 162, 184, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
}
.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  color: #fff;
  background: #28a745;
  border: 1px solid #28a745;
}
.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #218838;
  color: #fff;
  border-color: #1e7e34;
}
.p-button.p-button-success:enabled:focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.p-button.p-button-success:enabled:active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #1e7e34;
  color: #fff;
  border-color: #1c7430;
}
.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: #28a745;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(40, 167, 69, 0.04);
  color: #28a745;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(40, 167, 69, 0.16);
  color: #28a745;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: #28a745;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-success.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(40, 167, 69, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #28a745;
}
.p-button.p-button-success.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(40, 167, 69, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #28a745;
}
.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  color: #212529;
  background: #ffc107;
  border: 1px solid #ffc107;
}
.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #e0a800;
  color: #212529;
  border-color: #d39e00;
}
.p-button.p-button-warning:enabled:focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.p-button.p-button-warning:enabled:active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #d39e00;
  color: #212529;
  border-color: #c69500;
}
.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(255, 193, 7, 0.04);
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(255, 193, 7, 0.16);
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: #ffc107;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-warning.p-button-text:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(255, 193, 7, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #ffc107;
}
.p-button.p-button-warning.p-button-text:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(255, 193, 7, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #ffc107;
}
.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  color: #fff;
  background: #6f42c1;
  border: 1px solid #6f42c1;
}
.p-button.p-button-help:enabled:hover,
.p-buttonset.p-button-help > .p-button:enabled:hover,
.p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: #633bad;
  color: #fff;
  border-color: #58349a;
}
.p-button.p-button-help:enabled:focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #d3c6ec;
}
.p-button.p-button-help:enabled:active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help > .p-button:enabled:active {
  background: #58349a;
  color: #fff;
  border-color: #4d2e87;
}
.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(111, 66, 193, 0.04);
  color: #6f42c1;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(111, 66, 193, 0.16);
  color: #6f42c1;
  border: 1px solid;
}
.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-help.p-button-text:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(111, 66, 193, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
}
.p-button.p-button-help.p-button-text:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(111, 66, 193, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
}
.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: #fff;
  background: #e34a31;
  border: 1px solid #e34a31;
}
.p-button.p-button-danger:enabled:hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #c82333;
  color: #fff;
  border-color: #bd2130;
}
.p-button.p-button-danger:enabled:focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.p-button.p-button-danger:enabled:active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #bd2130;
  color: #fff;
  border-color: #b21f2d;
}
.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: rgba(0, 0, 0, 0);
  color: #e34a31;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(220, 53, 69, 0.04);
  color: #e34a31;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(220, 53, 69, 0.16);
  color: #e34a31;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: rgba(0, 0, 0, 0);
  color: #e34a31;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-danger.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(220, 53, 69, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #e34a31;
}
.p-button.p-button-danger.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(220, 53, 69, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #e34a31;
}
.p-button.p-button-link {
  color: var(--color-primary-500);
  background: rgba(0, 0, 0, 0);
  border: rgba(0, 0, 0, 0);
}
.p-button.p-button-link:enabled:hover {
  background: rgba(0, 0, 0, 0);
  color: #691b67;
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
  background: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
  border-color: rgba(0, 0, 0, 0);
}
.p-button.p-button-link:enabled:active {
  background: rgba(0, 0, 0, 0);
  color: var(--color-primary-500);
  border-color: rgba(0, 0, 0, 0);
}
.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
}
.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 1.3rem;
}
.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  background: #495057;
  color: #fff;
}
.p-speeddial-action:hover {
  background: #343a40;
  color: #fff;
}
.p-speeddial-direction-up .p-speeddial-item {
  margin: 0.25rem 0;
}
.p-speeddial-direction-up .p-speeddial-item:first-child {
  margin-bottom: 0.5rem;
}
.p-speeddial-direction-down .p-speeddial-item {
  margin: 0.25rem 0;
}
.p-speeddial-direction-down .p-speeddial-item:first-child {
  margin-top: 0.5rem;
}
.p-speeddial-direction-left .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-left .p-speeddial-item:first-child {
  margin-right: 0.5rem;
}
.p-speeddial-direction-right .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-right .p-speeddial-item:first-child {
  margin-left: 0.5rem;
}
.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
  margin: 0;
}
.p-speeddial-circle .p-speeddial-item:first-child,
.p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
  margin: 0;
}
.p-speeddial-mask {
  background-color: rgba(0, 0, 0, 0.4);
}
.p-splitbutton {
  border-radius: 4px;
}
.p-splitbutton.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-primary-500);
  border: 1px solid;
}
.p-splitbutton.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(0, 123, 255, 0.04);
  color: var(--color-primary-500);
}
.p-splitbutton.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(0, 123, 255, 0.16);
  color: var(--color-primary-500);
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
  color: #6c757d;
  border-color: #6c757d;
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined.p-button-plain
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: var(--color-background-light);
  color: #6c757d;
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-outlined.p-button-plain
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: var(--color-background-dark);
  color: #6c757d;
}
.p-splitbutton.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-primary-500);
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(0, 123, 255, 0.04);
  color: var(--color-primary-500);
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(0, 123, 255, 0.16);
  color: var(--color-primary-500);
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-text.p-button-plain > .p-button {
  color: #6c757d;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-text.p-button-plain
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: var(--color-background-light);
  color: #6c757d;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-text.p-button-plain
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: var(--color-background-dark);
  color: #6c757d;
}
.p-splitbutton.p-button-raised {
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-splitbutton.p-button-rounded {
  border-radius: 2rem;
}
.p-splitbutton.p-button-rounded > .p-button {
  border-radius: 2rem;
}
.p-splitbutton.p-button-sm > .p-button {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-splitbutton.p-button-sm > .p-button .p-button-icon {
  font-size: 0.875rem;
}
.p-splitbutton.p-button-lg > .p-button {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}
.p-splitbutton.p-button-lg > .p-button .p-button-icon {
  font-size: 1.25rem;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #6c757d;
  border: 1px solid;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(108, 117, 125, 0.04);
  color: #6c757d;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(108, 117, 125, 0.16);
  color: #6c757d;
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #6c757d;
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(108, 117, 125, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #6c757d;
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(108, 117, 125, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #6c757d;
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
  border: 1px solid;
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(23, 162, 184, 0.04);
  color: #17a2b8;
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(23, 162, 184, 0.16);
  color: #17a2b8;
}
.p-splitbutton.p-button-info.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(23, 162, 184, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
}
.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(23, 162, 184, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #17a2b8;
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #28a745;
  border: 1px solid;
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(40, 167, 69, 0.04);
  color: #28a745;
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(40, 167, 69, 0.16);
  color: #28a745;
}
.p-splitbutton.p-button-success.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #28a745;
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(40, 167, 69, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #28a745;
}
.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(40, 167, 69, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #28a745;
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #ffc107;
  border: 1px solid;
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(255, 193, 7, 0.04);
  color: #ffc107;
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(255, 193, 7, 0.16);
  color: #ffc107;
}
.p-splitbutton.p-button-warning.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #ffc107;
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(255, 193, 7, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #ffc107;
}
.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(255, 193, 7, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #ffc107;
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
  border: 1px solid;
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(111, 66, 193, 0.04);
  color: #6f42c1;
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(111, 66, 193, 0.16);
  color: #6f42c1;
}
.p-splitbutton.p-button-help.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(111, 66, 193, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
}
.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(111, 66, 193, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #6f42c1;
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #e34a31;
  border: 1px solid;
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(220, 53, 69, 0.04);
  color: #e34a31;
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-outlined
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(220, 53, 69, 0.16);
  color: #e34a31;
}
.p-splitbutton.p-button-danger.p-button-text > .p-button {
  background-color: rgba(0, 0, 0, 0);
  color: #e34a31;
  border-color: rgba(0, 0, 0, 0);
}
.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(220, 53, 69, 0.04);
  border-color: rgba(0, 0, 0, 0);
  color: #e34a31;
}
.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-text
  > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(220, 53, 69, 0.16);
  border-color: rgba(0, 0, 0, 0);
  color: #e34a31;
}
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: var(--color-background-dark);
  width: 2rem;
  height: 0.5rem;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: var(--color-background-dark);
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: var(--color-primary-500);
  color: #fff;
}
.p-datatable .p-datatable-table {
  width: 100%;
}
.p-datatable .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: var(--color-background-dark);
  color: #212529;
  border: solid var(--color-border);
  border-width: 1px 1px 0 1px;
  padding: 0;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: var(--color-background-dark);
  color: var(--color-contrast-dark);
  border: 1px solid var(--color-border);
  border-width: 1px;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0.75rem 0.5rem;
  border: 1px solid var(--color-border);
  border-width: 1px 1px 0 1px;
  font-weight: 600;
  color: var(--color-contrast-dark);
  background: var(--color-background-dark);
  transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-thead > tr:nth-child(2) > th {
  padding-top: 0;
  border-top: 0;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 0.75rem 0.5rem;
  border: 1px solid var(--color-border);
  border-width: 1px 0 1px 0;
  font-weight: 600;
  color: #212529;
  background: var(--color-background-light);
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.25rem;
  font-size: 0.7142rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #fff;
  background: var(--color-primary-500);
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  box-shadow: 0 3px 0 0 #91268f inset;
  color: var(--color-primary-500);
}
.p-datatable
  .p-sortable-column:not(.p-highlight):hover
  .p-sortable-column-icon {
  color: #343a40;
}
.p-datatable .p-sortable-column.p-highlight {
  background: var(--color-background-light);
  color: var(--color-primary-500);
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--color-primary-500);
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: var(--color-background-light);
  color: var(--color-primary-500);
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: var(--color-primary-500);
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: var(--color-background-light);
  color: #212529;
  transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid var(--color-border);
  border-width: 1px;
  padding: 0.75rem 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr:focus {
  outline: 0.15rem solid rgba(145, 38, 143, 0.5);
  outline-offset: 0.15rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: var(--color-primary-500);
  color: #fff;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #91268f;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #91268f;
}
.p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):hover {
  background: var(--color-background-light);
  color: #212529;
}
.p-datatable .p-column-resizer-helper {
  background: var(--color-primary-500);
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #efefef;
}
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-thead,
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-tfoot {
  background-color: #fff;
}

.p-datatable > .p-datatable-wrapper {
  // overflow: visible !important;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.03);
}
.p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr:nth-child(even).p-highlight {
  background: var(--color-primary-500);
  color: #fff;
}
.p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr:nth-child(even).p-highlight
  .p-row-toggler {
  color: #fff;
}
.p-datatable.p-datatable-striped
  .p-datatable-tbody
  > tr:nth-child(even).p-highlight
  .p-row-toggler:hover {
  color: #fff;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}
.p-dataview .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #efefef;
  color: #212529;
  border: solid var(--color-border);
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  background: var(--color-background-light);
  color: #212529;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: 1px solid var(--color-border);
  border-width: 1px 0 0 0;
}
.p-dataview .p-dataview-footer {
  background: #efefef;
  color: #212529;
  border: 1px solid var(--color-border);
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}
.p-dataview .p-dataview-emptymessage {
  padding: 1.25rem;
}
.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}
.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-column-filter-menu-button:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: rgba(0, 0, 0, 0);
  color: #495057;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: var(--color-primary-500);
  color: #fff;
}
.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-column-filter-clear-button:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-column-filter-overlay {
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
  min-width: 12.5rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.5rem 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item.p-highlight {
  color: #fff;
  background: var(--color-primary-500);
}
.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: var(--color-background-light);
}
.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid var(--color-background-dark);
  margin: 0.5rem 0;
}
.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid var(--color-background-dark);
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1.25rem;
  border-bottom: 1px solid var(--color-background-dark);
}
.p-column-filter-overlay-menu
  .p-column-filter-constraint
  .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu
  .p-column-filter-constraint
  .p-column-filter-remove-button {
  margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.5rem 1.25rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1.25rem;
}
.fc.fc-unthemed .fc-view-container th {
  background: #efefef;
  border: 1px solid var(--color-border);
  color: #212529;
}
.fc.fc-unthemed .fc-view-container td.fc-widget-content {
  background: var(--color-background-light);
  border: 1px solid var(--color-border);
  color: #212529;
}
.fc.fc-unthemed .fc-view-container td.fc-head-container {
  border: 1px solid var(--color-border);
}
.fc.fc-unthemed .fc-view-container .fc-row {
  border-right: 1px solid var(--color-background-dark);
}
.fc.fc-unthemed .fc-view-container .fc-event {
  background: #691b67;
  border: 1px solid #691b67;
  color: #fff;
}
.fc.fc-unthemed .fc-view-container .fc-divider {
  background: #efefef;
  border: 1px solid var(--color-border);
}
.fc.fc-unthemed .fc-toolbar .fc-button {
  color: #fff;
  background: var(--color-primary-500);
  border: 1px solid #91268f;
  font-size: 1rem;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
  background: #691b67;
  color: #fff;
  border-color: #691b67;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
  background: #691b67;
  color: #fff;
  border-color: #691b67;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}
.fc.fc-unthemed .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #fff;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #5a6268;
  border-color: #545b62;
  color: #fff;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.fc.fc-unthemed
  .fc-toolbar
  .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-unthemed
  .fc-toolbar
  .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-unthemed
  .fc-toolbar
  .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
  z-index: 1;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
  border-color: var(--color-border);
}
.fc.fc-theme-standard .fc-view-harness th {
  background: #efefef;
  border-color: var(--color-border);
  color: #212529;
}
.fc.fc-theme-standard .fc-view-harness td {
  color: #212529;
  border-color: var(--color-border);
}
.fc.fc-theme-standard .fc-view-harness .fc-view {
  background: var(--color-background-light);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover {
  background: none;
  border: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
  border: 1px solid var(--color-border);
  padding: 1rem 1.25rem;
  background: #efefef;
  color: #212529;
}
.fc.fc-theme-standard
  .fc-view-harness
  .fc-popover
  .fc-popover-header
  .fc-popover-close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: "PrimeIcons" !important;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.fc.fc-theme-standard
  .fc-view-harness
  .fc-popover
  .fc-popover-header
  .fc-popover-close:before {
  content: "";
}
.fc.fc-theme-standard
  .fc-view-harness
  .fc-popover
  .fc-popover-header
  .fc-popover-close:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.fc.fc-theme-standard
  .fc-view-harness
  .fc-popover
  .fc-popover-header
  .fc-popover-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
  padding: 1.25rem;
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  color: #212529;
  border-top: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  color: #fff;
  background: #691b67;
  border-color: #691b67;
}
.fc.fc-theme-standard
  .fc-view-harness
  .fc-event.fc-daygrid-block-event
  .fc-event-main {
  color: #fff;
}
.fc.fc-theme-standard
  .fc-view-harness
  .fc-event.fc-daygrid-dot-event
  .fc-daygrid-event-dot {
  background: #691b67;
  border-color: #691b67;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  background: var(--color-background-light);
  color: #212529;
}
.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
  background: #efefef;
}
.fc.fc-theme-standard .fc-toolbar .fc-button {
  color: var(--color-primary-500);
  background: rgba(0, 0, 0, 0);
  border: 1px solid #91268f;
  font-size: 1rem;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  border-radius: 4px;
  padding: 0 0.5rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: #691b67;
  color: #fff;
  border-color: #691b67;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
  background: #691b67;
  color: #fff;
  border-color: #691b67;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  opacity: 0.65;
  color: #fff;
  background: var(--color-primary-500);
  border: 1px solid #91268f;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}
.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #fff;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #5a6268;
  border-color: #545b62;
  color: #fff;
}
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #fff;
}
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridWeek-button:not(:disabled):focus,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
  z-index: 1;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.fc.fc-theme-standard a {
  color: #212529;
}
.fc.fc-theme-standard .fc-highlight {
  color: #fff;
  background: var(--color-primary-500);
}
.p-orderlist .p-orderlist-controls {
  padding: 1.25rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: #efefef;
  color: #212529;
  border: 1px solid var(--color-border);
  padding: 1rem 1.25rem;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 600;
}
.p-orderlist .p-orderlist-filter-container {
  padding: 1rem 1.25rem;
  background: var(--color-background-light);
  border: 1px solid var(--color-border);
  border-bottom: 0 none;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 1.75rem;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  color: #212529;
  padding: 0.5rem 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.5rem 1.5rem;
  margin: 0;
  border: 0 none;
  color: #212529;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #fff;
  background: var(--color-primary-500);
}
.p-orderlist .p-orderlist-list .p-orderlist-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
}
.p-orderlist
  .p-orderlist-list:not(.cdk-drop-list-dragging)
  .p-orderlist-item:not(.p-highlight):hover {
  background: var(--color-background-light);
  color: #212529;
}
.p-orderlist.p-orderlist-striped
  .p-orderlist-list
  .p-orderlist-item:nth-child(even) {
  background: rgba(0, 0, 0, 0.05);
}
.p-orderlist.p-orderlist-striped
  .p-orderlist-list
  .p-orderlist-item:nth-child(even):hover {
  background: var(--color-background-light);
}
.p-orderlist-item.cdk-drag-preview {
  padding: 0.5rem 1.5rem;
  box-shadow: none;
  border: 0 none;
  color: #212529;
  background: var(--color-background-light);
  margin: 0;
}
.p-organizationchart
  .p-organizationchart-node-content.p-organizationchart-selectable-node:not(
    .p-highlight
  ):hover {
  background: var(--color-background-light);
  color: #212529;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: var(--color-primary-500);
  color: #fff;
}
.p-organizationchart
  .p-organizationchart-node-content.p-highlight
  .p-node-toggler
  i {
  color: #003e80;
}
.p-organizationchart .p-organizationchart-line-down {
  background: var(--color-background-dark);
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid var(--color-background-dark);
  border-color: var(--color-border);
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid var(--color-background-dark);
  border-color: var(--color-border);
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  color: #212529;
  padding: 1.25rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-paginator {
  background: var(--color-background-light);
  color: var(--color-primary-500);
  border: solid var(--color-border);
  border-width: 0;
  padding: 0.75rem;
  border-radius: 4px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: #fff;
  border: 1px solid var(--color-border);
  color: var(--color-primary-500);
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: var(--color-background-light);
  border-color: var(--color-border);
  color: var(--color-primary-500);
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 2.357rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 2.357rem;
}
.p-paginator .p-paginator-current {
  background-color: #fff;
  border: 1px solid var(--color-border);
  color: var(--color-primary-500);
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: #fff;
  border: 1px solid var(--color-border);
  color: var(--color-primary-500);
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--color-primary-500);
  border-color: var(--color-primary-500);
  color: var(--on-color-primary-500);
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: var(--color-background-dark);
  border-color: var(--color-border);
  color: var(--color-primary-500);
}
.p-picklist .p-picklist-buttons {
  padding: 1.25rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: var(--color-background-dark);
  color: var(--color-contrast-dark);
  border: 1px solid var(--color-border);
  padding: 1rem 1.25rem;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 600;
}
.p-picklist .p-picklist-filter-container {
  padding: 1rem 1.25rem;
  background: var(--color-background-light);
  border: 1px solid var(--color-border);
  border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.75rem;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 0.75rem;
  color: var(--color-contrast-light);
}
.p-picklist .p-picklist-list {
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  color: var(--color-contrast-dark);
  padding: 0.5rem 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1.5rem;
  margin: 0;
  border: 0 none;
  color: var(--color-contrast-dark);
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--color-primary-opacity-50);
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: var(--on-color-primary-500);
  background: var(--color-primary-500);
}
.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
}
.p-picklist
  .p-picklist-list:not(.cdk-drop-list-dragging)
  .p-picklist-item:not(.p-highlight):hover {
  background: var(--color-background-dark);
  color: #212529;
}
.p-picklist.p-picklist-striped
  .p-picklist-list
  .p-picklist-item:nth-child(even) {
  background: rgba(0, 0, 0, 0.05);
}
.p-picklist.p-picklist-striped
  .p-picklist-list
  .p-picklist-item:nth-child(even):hover {
  background: var(--color-background-light);
}
.p-picklist-item.cdk-drag-preview {
  padding: 0.5rem 1.5rem;
  box-shadow: none;
  border: 0 none;
  color: #212529;
  background: var(--color-background-light);
  margin: 0;
}
.p-timeline .p-timeline-event-marker {
  border: 0 none;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: var(--color-primary-500);
}
.p-timeline .p-timeline-event-connector {
  background-color: var(--color-background-dark);
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}
.p-tree {
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  color: var(--color-contrast-dark);
  padding: 1.25rem;
  border-radius: 4px;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 4px;
  transition: box-shadow 0.15s;
  padding: 0.286rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #6c757d;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-checkbox
  .p-indeterminate
  .p-checkbox-icon {
  color: #212529;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem var(--color-primary-opacity-50);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: var(--color-primary-500);
  color: #fff;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-tree-toggler,
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-treenode-icon {
  color: #fff;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-tree-toggler:hover,
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-treenode-icon:hover {
  color: #fff;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: var(--color-background-dark);
  color: #212529;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: var(--color-background-dark);
  color: #212529;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.75rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.75rem;
  color: #495057;
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: var(--color-primary-600);
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 4px;
  border: 1px solid avr(--color-border);
  background-color: #fff;
  color: var(--color-contrast-dark);
  padding: 0.286rem;
  transition: box-shadow 0.15s;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: var(--color-primary-500);
  color: #fff;
}
.p-tree.p-tree-horizontal
  .p-treenode
  .p-treenode-content.p-highlight
  .p-treenode-icon {
  color: #fff;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal
  .p-treenode
  .p-treenode-content
  .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}
.p-tree.p-tree-horizontal
  .p-treenode
  .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: var(--color-background-dark);
  color: #212529;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-treetable .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: #efefef;
  color: #212529;
  border: solid var(--color-border);
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-footer {
  background: #efefef;
  color: #212529;
  border: 1px solid var(--color-border);
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid var(--color-border);
  border-width: 1px 0 2px 0;
  font-weight: 600;
  color: #212529;
  background: var(--color-background-light);
  transition: box-shadow 0.15s;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid var(--color-border);
  border-width: 1px 0 1px 0;
  font-weight: 600;
  color: #212529;
  background: var(--color-background-light);
}
.p-treetable .p-sortable-column {
  outline-color: var(--color-primary-opacity-10);
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #fff;
  background: var(--color-primary-500);
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: var(--color-background-light);
  color: #212529;
}
.p-treetable
  .p-sortable-column:not(.p-highlight):hover
  .p-sortable-column-icon {
  color: #6c757d;
}
.p-treetable .p-sortable-column.p-highlight {
  background: var(--color-background-light);
  color: var(--color-primary-500);
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--color-primary-500);
}
.p-treetable .p-treetable-tbody > tr {
  background: var(--color-background-light);
  color: #212529;
  transition: box-shadow 0.15s;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid var(--color-border);
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable
  .p-treetable-tbody
  > tr
  > td
  p-treetablecheckbox
  .p-checkbox
  .p-indeterminate
  .p-checkbox-icon {
  color: #212529;
}
.p-treetable .p-treetable-tbody > tr:focus {
  outline: 0.15rem solid var(--color-primary-opacity-10);
  outline-offset: -0.15rem;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: var(--color-primary-500);
  color: #fff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #fff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #fff;
}
.p-treetable.p-treetable-hoverable-rows
  .p-treetable-tbody
  > tr:not(.p-highlight):hover {
  background: var(--color-background-light);
  color: #212529;
}
.p-treetable.p-treetable-hoverable-rows
  .p-treetable-tbody
  > tr:not(.p-highlight):hover
  .p-treetable-toggler {
  color: #212529;
}
.p-treetable .p-column-resizer-helper {
  background: var(--color-primary-500);
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #efefef;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}
.p-virtualscroller .p-virtualscroller-header {
  background: var(--color-background-dark);
  color: #212529;
  border: solid var(--color-border);
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-virtualscroller .p-virtualscroller-content {
  background: var(--color-background-light);
  color: #212529;
  border: 0 none;
  padding: 0;
}
.p-virtualscroller .p-virtualscroller-footer {
  background: var(--color-background-dark);
  color: #212529;
  border: 1px solid var(--color-border);
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem 1.25rem;
  border: 1px solid var(--color-border);
  color: #212529;
  background: var(--color-background-dark);
  font-weight: 600;
  border-radius: 4px;
  transition: box-shadow 0.15s;
}
.p-accordion
  .p-accordion-header
  .p-accordion-header-link
  .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: var(--color-background-light);
  border-color: var(--color-border);
  color: #212529;
}
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: #efefef;
  border-color: var(--color-border);
  color: #212529;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link {
  border-color: var(--color-border);
  background: var(--color-background-light);
  color: #212529;
}
.p-accordion .p-accordion-content {
  padding: 1.25rem;
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  color: #212529;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}
.p-accordion
  p-accordiontab:not(:first-child)
  .p-accordion-header
  .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion
  p-accordiontab:not(:first-child)
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link,
.p-accordion
  p-accordiontab:not(:first-child)
  .p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion
  p-accordiontab:first-child
  .p-accordion-header
  .p-accordion-header-link {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-accordion
  p-accordiontab:last-child
  .p-accordion-header:not(.p-highlight)
  .p-accordion-header-link {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-card {
  background: var(--color-background-light);
  color: #212529;
  box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-card .p-card-body {
  padding: 1.5rem;
}
.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #6c757d;
}
.p-card .p-card-content {
  padding: 1rem 0;
}
.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}
.p-divider .p-divider-content {
  background-color: #fff;
}
.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px var(--color-background-dark);
}
.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px var(--color-background-dark);
}
.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}
.p-fieldset {
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  color: #212529;
  border-radius: 4px;
}
.p-fieldset .p-fieldset-legend {
  padding: 1rem 1.25rem;
  border: 1px solid var(--color-border);
  color: #212529;
  background: #efefef;
  font-weight: 600;
  border-radius: 4px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: box-shadow 0.15s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem 1.25rem;
  color: #212529;
  border-radius: 4px;
  transition: box-shadow 0.15s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: var(--color-background-light);
  border-color: var(--color-border);
  color: #212529;
}
.p-fieldset .p-fieldset-content {
  padding: 1.25rem;
}
.p-panel .p-panel-header {
  border: 1px solid var(--color-border);
  padding: 1rem 1.25rem;
  background: #efefef;
  color: #212529;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1.25rem;
}
.p-panel .p-panel-content {
  padding: 1.25rem;
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  color: #212529;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 0.5rem 1.25rem;
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  color: #212529;
  border-top: 0 none;
}
.p-panel .p-panel-icons-end {
  order: 2;
  margin-left: auto;
}
.p-panel .p-panel-icons-start {
  order: 0;
  margin-right: 0.5rem;
}
.p-panel .p-panel-icons-center {
  order: 2;
  width: 100%;
  text-align: center;
}
.p-scrollpanel .p-scrollpanel-bar {
  background: #efefef;
  border: 0 none;
}
.p-splitter {
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  border-radius: 4px;
  color: #212529;
}
.p-splitter .p-splitter-gutter {
  transition: box-shadow 0.15s;
  background: #efefef;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: var(--color-background-dark);
}
.p-splitter .p-splitter-gutter-resizing {
  background: var(--color-background-dark);
}
.p-tabview .p-tabview-nav {
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--color-border);
  border-width: 0 0 1px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid;
  border-width: 1px;
  border-color: #fff #fff var(--color-background-dark) #fff;
  background: var(--color-background-light);
  color: #6c757d;
  padding: 0.75rem 1rem;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: box-shadow 0.15s;
  margin: 0 0 -1px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  background: var(--color-background-light);
  border-color: var(--color-border);
  color: #6c757d;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: var(--color-background-light);
  border-color: var(--color-background-dark) var(--color-background-dark) #fff
    var(--color-background-dark);
  color: #495057;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-nav-btn.p-link {
  background: var(--color-background-light);
  color: #495057;
  width: 2.357rem;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-tabview .p-tabview-panels {
  background: var(--color-background-light);
  padding: 1.25rem;
  border: 0 none;
  color: #212529;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-toolbar {
  background: #efefef;
  border: 1px solid var(--color-border);
  padding: 1rem 1.25rem;
  border-radius: 4px;
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}
.p-confirm-popup {
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: none;
}
.p-confirm-popup .p-confirm-popup-content {
  padding: 1.25rem;
}
.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0.5rem 1.25rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}
.p-confirm-popup:after {
  border: solid rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
}
.p-confirm-popup:before {
  border: solid rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #fff;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: rgba(0, 0, 0, 0.2);
}
.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}
.p-dialog {
  border-radius: 4px;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.16),
    0 6px 24px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.p-dialog .p-dialog-header {
  border-bottom: 1px solid var(--color-background-dark);
  background-color: var(--primary-500);
  color: var(--white);
  padding: 1rem;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: var(--white);
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: var(--color-background-light);
  color: #212529;
  width: 100%;
  height: min-content;
  max-height: 500px;
  padding: 1rem;
}
.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer {
  border-top: 1px solid var(--color-background-dark);
  background: var(--color-background-light);
  color: #212529;
  padding: 1rem;
  text-align: right;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer button {
  margin: 0;
  width: auto;
}
.p-dialog .p-dialog-footer button + button {
  margin-left: 0.5rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}
.p-overlaypanel {
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: none;
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1.25rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: var(--color-primary-500);
  color: #fff;
  width: 2rem;
  height: 2rem;
  transition: box-shadow 0.15s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #691b67;
  color: #fff;
}
.p-overlaypanel:after {
  border: solid rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
}
.p-overlaypanel:before {
  border: solid rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #fff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: rgba(0, 0, 0, 0.2);
}
.p-sidebar {
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
}
.p-sidebar .p-sidebar-header {
  padding: 1rem 1.25rem;
}
.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: box-shadow 0.15s;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: #495057;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
}
.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}
.p-sidebar .p-sidebar-content {
  padding: 1.25rem;
}
.p-tooltip .p-tooltip-text {
  background: #212529;
  color: #fff;
  padding: 0.5rem 0.75rem;
  box-shadow: none;
  border-radius: 4px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #212529;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #212529;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #212529;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #212529;
}
.p-fileupload .p-fileupload-buttonbar {
  background: #efefef;
  padding: 1rem 1.25rem;
  border: 1px solid var(--color-border);
  color: #212529;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-fileupload .p-fileupload-content {
  background: var(--color-background-light);
  padding: 1rem;
  border: 1px solid var(--color-border);
  color: #212529;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}
.p-fileupload-choose:not(.p-disabled):hover {
  background: #691b67;
  color: #fff;
  border-color: #691b67;
}
.p-fileupload-choose:not(.p-disabled):active {
  background: #691b67;
  color: #fff;
  border-color: #691b67;
}
.p-breadcrumb {
  background: #efefef;
  border: 0 none;
  border-radius: 4px;
  padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: box-shadow 0.15s;
  border-radius: 4px;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: var(--color-primary-500);
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: var(--color-primary-500);
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #6c757d;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #6c757d;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #6c757d;
}
.p-contextmenu {
  padding: 0.5rem 0;
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 4px;
  width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--color-background-light);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-contextmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: var(--color-background-light);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 4px;
}
.p-contextmenu .p-menuitem {
  margin: 0;
}
.p-contextmenu .p-menuitem:last-child {
  margin: 0;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: var(--color-background-light);
}
.p-contextmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text {
  color: #212529;
}
.p-contextmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-icon,
.p-contextmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-submenu-icon {
  color: #212529;
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid var(--color-background-dark);
  margin: 0.5rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}
.p-contextmenu .p-menuitem-badge {
  background: var(--color-primary-500);
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 4px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.p-dock .p-dock-list {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
}
.p-dock .p-dock-item {
  padding: 0.5rem;
}
.p-dock .p-dock-action {
  width: 4rem;
  height: 4rem;
}
.p-dock.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-top .p-dock-item-second-next,
.p-dock.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-bottom .p-dock-item-second-next {
  margin: 0 0.9rem;
}
.p-dock.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-top .p-dock-item-next,
.p-dock.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-bottom .p-dock-item-next {
  margin: 0 1.3rem;
}
.p-dock.p-dock-top .p-dock-item-current,
.p-dock.p-dock-bottom .p-dock-item-current {
  margin: 0 1.5rem;
}
.p-dock.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-left .p-dock-item-second-next,
.p-dock.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-right .p-dock-item-second-next {
  margin: 0.9rem 0;
}
.p-dock.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-left .p-dock-item-next,
.p-dock.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-right .p-dock-item-next {
  margin: 1.3rem 0;
}
.p-dock.p-dock-left .p-dock-item-current,
.p-dock.p-dock-right .p-dock-item-current {
  margin: 1.5rem 0;
}
@media screen and (max-width: 960px) {
  .p-dock.p-dock-top .p-dock-list-container,
  .p-dock.p-dock-bottom .p-dock-list-container {
    overflow-x: auto;
    width: 100%;
  }
  .p-dock.p-dock-top .p-dock-list-container .p-dock-list,
  .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
    margin: 0 auto;
  }
  .p-dock.p-dock-left .p-dock-list-container,
  .p-dock.p-dock-right .p-dock-list-container {
    overflow-y: auto;
    height: 100%;
  }
  .p-dock.p-dock-left .p-dock-list-container .p-dock-list,
  .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
    margin: auto 0;
  }
  .p-dock .p-dock-list .p-dock-item {
    transform: none;
    margin: 0;
  }
}
.p-megamenu {
  padding: 0.5rem 1rem;
  background: #efefef;
  color: rgba(0, 0, 0, 0.9);
  border: 0 none;
  border-radius: 4px;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-text {
  color: rgba(0, 0, 0, 0.5);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 0.5rem;
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-submenu-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.5rem;
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: rgba(0, 0, 0, 0.7);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.7);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: rgba(0, 0, 0, 0.7);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: rgba(0, 0, 0, 0.9);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-icon,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.9);
}
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-submenu-icon,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: rgba(0, 0, 0, 0.9);
}
.p-megamenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--color-background-light);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-megamenu .p-megamenu-panel {
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: var(--color-background-light);
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-megamenu .p-megamenu-submenu {
  padding: 0.5rem 0;
  width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid var(--color-background-dark);
  margin: 0.5rem 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem {
  margin: 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
  margin: 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: var(--color-background-light);
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.5rem 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem {
  margin: 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
  margin: 0;
}
.p-megamenu .p-menuitem-badge {
  background: var(--color-primary-500);
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 4px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.p-menu {
  padding: 0.5rem 0;
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  width: 12.5rem;
}
.p-menu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--color-background-light);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-menu.p-menu-overlay {
  background: var(--color-background-light);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: var(--color-background-light);
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid var(--color-background-dark);
  margin: 0.5rem 0;
}
.p-menu .p-menuitem {
  margin: 0;
}
.p-menu .p-menuitem:last-child {
  margin: 0;
}
.p-menu .p-menuitem-badge {
  background: var(--color-primary-500);
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 4px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.p-menubar {
  padding: 0.5rem 1rem;
  background: #efefef;
  color: rgba(0, 0, 0, 0.9);
  border: 0 none;
  border-radius: 4px;
}
.p-menubar .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: var(--color-background-light);
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-text {
  color: rgba(0, 0, 0, 0.5);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 0.5rem;
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-submenu-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.5rem;
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: rgba(0, 0, 0, 0.7);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.7);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: rgba(0, 0, 0, 0.7);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: rgba(0, 0, 0, 0.9);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-icon,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.9);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-submenu-icon,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: rgba(0, 0, 0, 0.9);
}
.p-menubar .p-submenu-list {
  padding: 0.5rem 0;
  background: var(--color-background-light);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid var(--color-background-dark);
  margin: 0.5rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}
.p-menubar .p-submenu-list .p-menuitem:last-child {
  margin: 0;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: var(--color-background-light);
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-menubar .p-menuitem-badge {
  background: var(--color-primary-500);
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 4px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: box-shadow 0.15s;
  }
  .p-menubar .p-menubar-button:hover {
    color: rgba(0, 0, 0, 0.7);
    background: rgba(0, 0, 0, 0);
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.5rem 0;
    background: var(--color-background-light);
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid var(--color-background-dark);
    margin: 0.5rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-text {
    color: #212529;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-icon {
    color: #212529;
    margin-right: 0.5rem;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-submenu-icon {
    color: #212529;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover {
    background: var(--color-background-light);
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: #212529;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: #212529;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: #212529;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.15s;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.15s;
    transform: rotate(90deg);
  }
  .p-menubar
    .p-menubar-root-list
    .p-submenu-list
    .p-menuitem-active
    > .p-menuitem-link
    > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1rem 1.25rem;
  border: 1px solid var(--color-border);
  color: #212529;
  background: #efefef;
  font-weight: 600;
  border-radius: 4px;
  transition: box-shadow 0.15s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: var(--color-background-light);
  border-color: var(--color-border);
  color: #212529;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #efefef;
  border-color: var(--color-border);
  color: #212529;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: var(--color-border);
  background: var(--color-background-light);
  color: #212529;
}
.p-panelmenu .p-panelmenu-content {
  padding: 0.5rem 0;
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  color: #212529;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link
  .p-menuitem-text {
  color: #212529;
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link
  .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover {
  background: var(--color-background-light);
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: #212529;
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: #212529;
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: #212529;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link
  .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu
  .p-panelmenu-content
  .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}
.p-panelmenu
  .p-panelmenu-panel:not(:first-child)
  .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover
  > a,
.p-panelmenu
  .p-panelmenu-panel:not(:first-child)
  .p-panelmenu-header:not(.p-disabled).p-highlight:hover
  > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-panelmenu
  .p-panelmenu-panel:last-child
  .p-panelmenu-header:not(.p-highlight)
  > a {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-panelmenu .p-menuitem-badge {
  background: var(--color-primary-500);
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 4px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.p-slidemenu {
  padding: 0.5rem 0;
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--color-background-light);
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-slidemenu.p-slidemenu-overlay {
  background: var(--color-background-light);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-slidemenu .p-slidemenu-list {
  padding: 0.5rem 0;
  background: var(--color-background-light);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: var(--color-background-light);
}
.p-slidemenu
  .p-slidemenu.p-slidemenu-active
  > .p-slidemenu-link
  .p-slidemenu-text {
  color: #212529;
}
.p-slidemenu
  .p-slidemenu.p-slidemenu-active
  > .p-slidemenu-link
  .p-slidemenu-icon,
.p-slidemenu
  .p-slidemenu.p-slidemenu-active
  > .p-slidemenu-link
  .p-slidemenu-icon {
  color: #212529;
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid var(--color-background-dark);
  margin: 0.5rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 0.75rem 1rem;
  color: #212529;
}
.p-slidemenu .p-menuitem-badge {
  background: var(--color-primary-500);
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 4px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.p-steps .p-steps-item .p-menuitem-link {
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0);
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #212529;
  border: 1px solid var(--color-border);
  background: rgba(0, 0, 0, 0);
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 4px;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #6c757d;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--color-primary-500);
  color: #fff;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #212529;
}
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid var(--color-background-dark);
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}
.p-tabmenu .p-tabmenu-nav {
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--color-border);
  border-width: 0 0 1px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid;
  border-width: 1px;
  border-color: #fff #fff var(--color-background-dark) #fff;
  background: var(--color-background-light);
  color: #6c757d;
  padding: 0.75rem 1rem;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: box-shadow 0.15s;
  margin: 0 0 -1px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover
  .p-menuitem-link {
  background: var(--color-background-light);
  border-color: var(--color-border);
  color: #6c757d;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: var(--color-background-light);
  border-color: var(--color-background-dark) var(--color-background-dark) #fff
    var(--color-background-dark);
  color: #495057;
}
.p-tabmenu .p-tabmenu-left-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-right-icon {
  margin-left: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav-btn.p-link {
  background: var(--color-background-light);
  color: #495057;
  width: 2.357rem;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
.p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-tieredmenu {
  padding: 0.5rem 0;
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--color-background-light);
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(145, 38, 143, 0.5);
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: var(--color-background-light);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-tieredmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: var(--color-background-light);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-tieredmenu .p-menuitem {
  margin: 0;
}
.p-tieredmenu .p-menuitem:last-child {
  margin: 0;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: var(--color-background-light);
}
.p-tieredmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text {
  color: #212529;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #212529;
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid var(--color-background-dark);
  margin: 0.5rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}
.p-tieredmenu .p-menuitem-badge {
  background: var(--color-primary-500);
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 4px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.p-inline-message {
  padding: 0.5rem 0.75rem;
  margin: 0;
  border-radius: 4px;
}
.p-inline-message.p-inline-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 0px;
  color: #004085;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #004085;
}
.p-inline-message.p-inline-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 0px;
  color: #155724;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #155724;
}
.p-inline-message.p-inline-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 0px;
  color: #856404;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #856404;
}
.p-inline-message.p-inline-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 0px;
  color: #721c24;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #721c24;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}
.p-message {
  margin: 1rem 0;
  border-radius: 4px;
}
.p-message .p-message-wrapper {
  padding: 1rem 1.25rem;
}
.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-message.p-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 1px;
  color: #004085;
}
.p-message.p-message-info .p-message-icon {
  color: #004085;
}
.p-message.p-message-info .p-message-close {
  color: #004085;
}
.p-message.p-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 1px;
  color: #155724;
}
.p-message.p-message-success .p-message-icon {
  color: #155724;
}
.p-message.p-message-success .p-message-close {
  color: #155724;
}
.p-message.p-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 1px;
  color: #856404;
}
.p-message.p-message-warn .p-message-icon {
  color: #856404;
}
.p-message.p-message-warn .p-message-close {
  color: #856404;
}
.p-message.p-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 1px;
  color: #721c24;
}
.p-message.p-message-error .p-message-icon {
  color: #721c24;
}
.p-message.p-message-error .p-message-close {
  color: #721c24;
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-message .p-message-summary {
  font-weight: 700;
}
.p-message .p-message-detail {
  margin-left: 0.5rem;
}
.p-toast {
  opacity: 1;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-toast .p-toast-message.p-toast-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 1px;
  color: #004085;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #004085;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 1px;
  color: #155724;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #155724;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 1px;
  color: #856404;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #856404;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 1px;
  color: #721c24;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #721c24;
}
.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: rgba(0, 0, 0, 0);
  color: #efefef;
  width: 4rem;
  height: 4rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #efefef;
}
.p-galleria .p-galleria-item-nav {
  background: rgba(0, 0, 0, 0);
  color: #efefef;
  width: 4rem;
  height: 4rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
  margin: 0 0.5rem;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.1);
  color: #efefef;
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #efefef;
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: var(--color-background-dark);
  width: 1rem;
  height: 1rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: var(--color-background-dark);
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: var(--color-primary-500);
  color: #fff;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
.p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator,
.p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem
  .p-galleria-indicators
  .p-galleria-indicator
  button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem
  .p-galleria-indicators
  .p-galleria-indicator
  button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem
  .p-galleria-indicators
  .p-galleria-indicator.p-highlight
  button {
  background: var(--color-primary-500);
  color: #fff;
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: rgba(0, 0, 0, 0);
  color: #efefef;
  width: 2rem;
  height: 2rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #efefef;
}
.p-galleria
  .p-galleria-thumbnail-container
  .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-galleria-mask {
  --maskbg: rgba($black, 0.9);
}
.p-image-mask {
  --maskbg: rgba($black, 0.9);
}
.p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0);
  color: #f8f9fa;
  transition: box-shadow 0.15s;
}
.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.5);
}
.p-image-toolbar {
  padding: 1rem;
}
.p-image-action.p-link {
  color: #f8f9fa;
  background-color: rgba(0, 0, 0, 0);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin-right: 0.5rem;
}
.p-image-action.p-link:last-child {
  margin-right: 0;
}
.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.1);
}
.p-image-action.p-link i {
  font-size: 1.5rem;
}
.p-avatar {
  background-color: var(--color-background-dark);
  border-radius: 4px;
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}
.p-avatar-group .p-avatar {
  border: 2px solid #fff;
}
.p-badge {
  background: var(--color-primary-500);
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: #6c757d;
  color: #fff;
}
.p-badge.p-badge-success {
  background-color: #28a745;
  color: #fff;
}
.p-badge.p-badge-info {
  background-color: #17a2b8;
  color: #fff;
}
.p-badge.p-badge-warning {
  background-color: #ffc107;
  color: #212529;
}
.p-badge.p-badge-danger {
  background-color: #e34a31;
  color: #fff;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}
.p-chip {
  background-color: var(--color-background-dark);
  color: #212529;
  border-radius: 16px;
  padding: 0 0.75rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
}
.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -0.75rem;
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  border-radius: 4px;
  transition: box-shadow 0.15s;
}
.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-inplace .p-inplace-display {
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: var(--color-background-light);
  color: #212529;
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 143, 0.1);
}
.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: var(--color-background-light);
  border-radius: 4px;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: var(--color-primary-500);
}
.p-progressbar .p-progressbar-label {
  color: #fff;
  line-height: 1.5rem;
}
.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 4px;
  box-shadow: none;
  transition: box-shadow 0.15s;
}
.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
}
.p-scrolltop.p-link:hover {
  background: rgba(0, 0, 0, 0.8);
}
.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #efefef;
}
.p-skeleton {
  background-color: var(--color-background-dark);
  border-radius: 4px;
}
.p-skeleton:after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
}
.p-tag {
  background: var(--color-primary-500);
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
}
.p-tag.p-tag-success {
  background-color: #28a745;
  color: #fff;
}
.p-tag.p-tag-info {
  background-color: #17a2b8;
  color: #fff;
}
.p-tag.p-tag-warning {
  background-color: #ffc107;
  color: #212529;
}
.p-tag.p-tag-danger {
  background-color: #e34a31;
  color: #fff;
}
.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}
.p-terminal {
  background: var(--color-background-light);
  color: #212529;
  border: 1px solid var(--color-border);
  padding: 1.25rem;
}
.p-terminal .p-terminal-input {
  font-size: 1rem;
  font-family: "Inter", sans-serif;
}
.p-breadcrumb .p-breadcrumb-chevron {
  font-family: "Inter", sans-serif;
}
.p-breadcrumb .p-breadcrumb-chevron:before {
  content: "/";
}
.fc.fc-theme-standard .fc-highlight {
  background: #cce5ff;
}
html {
  height: 100%;
  min-height: 100vh;
  font-size: 14px;
}
body {
  min-height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
}
.rounded {
  border-radius: 50%;
  overflow: hidden;
}
.m-30 {
  margin: 30px;
}
.m-20 {
  margin: 20px;
}
.m-10 {
  margin: 10px;
}
.mtop-30 {
  margin-top: 30px;
}
.mtop-20 {
  margin-top: 20px;
}
.mtop-10 {
  margin-top: 10px;
}
.mbtm-30 {
  margin-bottom: 30px;
}
.mbtm-20 {
  margin-bottom: 20px;
}
.mbtm-10 {
  margin-bottom: 10px;
}
.mleft-30 {
  margin-left: 30px;
}
.mleft-20 {
  margin-left: 20px;
}
.mleft-10 {
  margin-left: 10px;
}
.mright-30 {
  margin-right: 30px;
}
.mright-20 {
  margin-right: 20px;
}
.mright-10 {
  margin-right: 10px;
}
.p-30 {
  padding: 30px;
}
.p-20 {
  padding: 20px;
}
.p-10 {
  padding: 10px;
}
.ptop-30 {
  padding-top: 30px;
}
.ptop-20 {
  padding-top: 20px;
}
.ptop-10 {
  padding-top: 10px;
}
.pbtm-30 {
  padding-bottom: 30px;
}
.pbtm-20 {
  padding-bottom: 20px;
}
.pbtm-10 {
  padding-bottom: 10px;
}
.pleft-30 {
  padding-left: 30px;
}
.pleft-20 {
  padding-left: 20px;
}
.pleft-10 {
  padding-left: 10px;
}
.pright-30 {
  padding-right: 30px;
}
.pright-20 {
  padding-right: 20px;
}
.pright-10 {
  padding-right: 10px;
}
.hidden {
  display: none;
}
.btn-tertiary {
  color: #fff;
  background-color: #333;
  border-color: #f1b434;
}
.btn-tertiary:hover {
  color: #212529;
  background-color: #f1b434;
  border-color: #e29e10;
}
.btn-tertiary:focus,
.btn-tertiary.focus {
  color: #212529;
  background-color: #f1b434;
  border-color: #e29e10;
  box-shadow: 0 0 0 0.2rem rgba(243, 191, 82, 0.5);
}
.btn-tertiary.disabled,
.btn-tertiary:disabled {
  color: #fff;
  background-color: #333;
  border-color: #f1b434;
}
.btn-tertiary:not(:disabled):not(.disabled):active,
.btn-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-tertiary.dropdown-toggle {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #d6960f;
}
.btn-tertiary:not(:disabled):not(.disabled):active:focus,
.btn-tertiary:not(:disabled):not(.disabled).active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 191, 82, 0.5);
}
.btn-excel {
  color: #fff;
  background-color: #1f6e43;
  border-color: #f9fffc;
}
.btn-excel:hover {
  color: #212529;
  background-color: #a0c3b0;
  border-color: #c6ffe3;
}
.btn-excel:focus,
.btn-excel.focus {
  color: #212529;
  background-color: #a0c3b0;
  border-color: #c6ffe3;
  box-shadow: 0 0 0 0.2rem rgba(250, 255, 252, 0.5);
}
.btn-excel.disabled,
.btn-excel:disabled {
  color: #fff;
  background-color: #1f6e43;
  border-color: #f9fffc;
}
.btn-excel:not(:disabled):not(.disabled):active,
.btn-excel:not(:disabled):not(.disabled).active,
.show > .btn-excel.dropdown-toggle {
  color: #fff;
  background-color: #14462b;
  border-color: #b9ffdc;
}
.btn-excel:not(:disabled):not(.disabled):active:focus,
.btn-excel:not(:disabled):not(.disabled).active:focus,
.show > .btn-excel.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 255, 252, 0.5);
}
.btn-word {
  color: #fff;
  background-color: #295497;
  border-color: #f5f9ff;
}
.btn-word:hover {
  color: #212529;
  background-color: #afc2df;
  border-color: #c2daff;
}
.btn-word:focus,
.btn-word.focus {
  color: #212529;
  background-color: #afc2df;
  border-color: #c2daff;
  box-shadow: 0 0 0 0.2rem rgba(247, 250, 255, 0.5);
}
.btn-word.disabled,
.btn-word:disabled {
  color: #fff;
  background-color: #295497;
  border-color: #f5f9ff;
}
.btn-word:not(:disabled):not(.disabled):active,
.btn-word:not(:disabled):not(.disabled).active,
.show > .btn-word.dropdown-toggle {
  color: #fff;
  background-color: #1e3e6f;
  border-color: #b5d3ff;
}
.btn-word:not(:disabled):not(.disabled):active:focus,
.btn-word:not(:disabled):not(.disabled).active:focus,
.show > .btn-word.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 250, 255, 0.5);
}
.btn-pdf {
  color: #fff;
  background-color: #ba3824;
  border-color: #fff8f7;
}
.btn-pdf:hover {
  color: #212529;
  background-color: #debcb7;
  border-color: #ffcbc4;
}
.btn-pdf:focus,
.btn-pdf.focus {
  color: #212529;
  background-color: #debcb7;
  border-color: #ffcbc4;
  box-shadow: 0 0 0 0.2rem rgba(255, 249, 248, 0.5);
}
.btn-pdf.disabled,
.btn-pdf:disabled {
  color: #fff;
  background-color: #ba3824;
  border-color: #fff8f7;
}
.btn-pdf:not(:disabled):not(.disabled):active,
.btn-pdf:not(:disabled):not(.disabled).active,
.show > .btn-pdf.dropdown-toggle {
  color: #fff;
  background-color: #8f2b1c;
  border-color: #ffc0b7;
}
.btn-pdf:not(:disabled):not(.disabled):active:focus,
.btn-pdf:not(:disabled):not(.disabled).active:focus,
.show > .btn-pdf.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 249, 248, 0.5);
}
.btn-delete.btn-link {
  color: #fff;
  background-color: #e34a31;
  border-color: rgba(0, 0, 0, 0);
}
.btn-delete.btn-link:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.btn-delete.btn-link:focus,
.btn-delete.btn-link.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-delete.btn-link.disabled,
.btn-delete.btn-link:disabled {
  color: #fff;
  background-color: #e34a31;
  border-color: rgba(0, 0, 0, 0);
}
.btn-delete.btn-link:not(:disabled):not(.disabled):active,
.btn-delete.btn-link:not(:disabled):not(.disabled).active,
.show > .btn-delete.btn-link.dropdown-toggle {
  color: #fff;
  background-color: #c6331b;
  border-color: rgba(0, 0, 0, 0);
}
.btn-delete.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-delete.btn-link:not(:disabled):not(.disabled).active:focus,
.show > .btn-delete.btn-link.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-default.btn-link {
  color: #fff;
  background-color: #8c8c8c;
  border-color: rgba(0, 0, 0, 0);
}
.btn-default.btn-link:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.btn-default.btn-link:focus,
.btn-default.btn-link.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-default.btn-link.disabled,
.btn-default.btn-link:disabled {
  color: #fff;
  background-color: #8c8c8c;
  border-color: rgba(0, 0, 0, 0);
}
.btn-default.btn-link:not(:disabled):not(.disabled):active,
.btn-default.btn-link:not(:disabled):not(.disabled).active,
.show > .btn-default.btn-link.dropdown-toggle {
  color: #fff;
  background-color: #737373;
  border-color: rgba(0, 0, 0, 0);
}
.btn-default.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-default.btn-link:not(:disabled):not(.disabled).active:focus,
.show > .btn-default.btn-link.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-icon {
  display: inline-flex;
  align-items: center;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  padding: 3px;
  border-radius: 20px;
  position: relative;
}
.btn-icon:hover {
  color: var(--color-primary-500);
}
.btn-icon:active,
.btn-icon:focus,
.btn-icon:active:hover,
.btn-icon:active:focus {
  color: var(--color-primary-500);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  outline: 0 none;
}
.btn-icon:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120%;
  height: 120%;
  background: rgba(0, 0, 0, 0);
  z-index: 0;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}
.btn-icon:hover:after {
  background: rgba(145, 38, 143, 0.05);
}
.btn {
  display: inline-flex;
  align-items: center;
}
.btn > span + span,
.btn > i + span {
  margin-left: 5px;
}
.btn span > span + span {
  margin-left: 5px;
}
.btn label {
  margin: 0;
}
.btn + * {
  margin-left: 8px;
}
.btn:focus {
  outline: 0;
}
.toggle-btn-block {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 12;
  width: 100%;
}
.btn-toggle-menu {
  background: none;
  border: 0;
  display: block;
  padding: 6px 18px;
  text-align: left;
}
.btn-toggle-menu:hover span {
  margin-left: 5px;
}
.btn-toggle-menu span {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: margin 0.2s ease-in-out;
  -moz-transition: margin 0.2s ease-in-out;
  transition: margin 0.2s ease-in-out;
}
.opened .btn-toggle-menu span {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.opened .btn-toggle-menu:hover span {
  margin-left: -5px;
}
.action-btns-group,
kuba-export {
  border-left: 1px solid var(--color-background-dark);
  position: relative;
  display: inline-block;
}
.table-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.table-options .pull-left {
  float: none !important;
  text-align: left;
  display: table-cell;
  vertical-align: middle;
}
.table-options .pull-right {
  margin-left: auto;
}
.table-options .pull-right .toggle-action-btn {
  color: #495057;
  display: inline-flex;
}
.table-options .pull-right .open-dropdown .toggle-action-btn {
  background-color: #fff;
}
.table-options .pull-right .action-btns-group + .action-btns-group {
  margin-left: -2px;
}
.open-dropdown:after {
  background: var(--color-background-light);
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  bottom: -1px;
  width: 100%;
  z-index: 999;
}
.text-tertiary {
  color: #f1b434;
}
.text-medium {
  color: #6c757d;
}
.thumb-xs {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
}
.thumb-xs:empty {
  width: auto;
  height: auto;
}
.thumb-sm {
  position: relative;
  width: 36px;
  height: 36px;
  display: inline-block;
}
.thumb-sm:empty {
  width: auto;
  height: auto;
}
.thumb-sm > * > a,
.thumb-xs > * > a {
  display: block;
  border-radius: 7px;
  overflow: hidden;
  width: 100%;
  position: absolute;
  height: 100%;
}
.img-preview {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.thumb-sm a > img,
.thumb-xs a > img,
.thumb-xs > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.grid-thumb {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  border: 1px solid #eaeaea;
}
.grid-thumb a {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.grid-thumb img {
  max-width: 100%;
  max-height: 100%;
}
body .p-datatable .p-datatable-header {
  text-align: left;
}
body .p-datatable a:not(.btn) {
  font-size: 15px;
  font-weight: bold;
}
body .p-datatable-table td img:not(.thumbnail) {
  max-width: 100%;
  height: auto;
  margin: 0;
}
body .p-datatable-table td .thumbnail {
  width: 45px;
  height: 45px;
  object-fit: cover;
  margin: auto;
}
body .p-datatable-table td .thumbnail[src=""][alt=""] {
  display: none;
}
.p-datatable-header .table-options .btn {
  padding: 12px 12px 13px;
  border: none;
}
.p-datatable-header .action-btns-group > .btn {
  color: #495057;
}
.input-filters {
  padding: 8px;
}
.input-filters .p-inputtext,
.input-filters .ui-dropdown {
  max-width: 180px;
}
.truncate-data {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  word-break: break-all;
}
.export-btns {
  display: none;
  background: var(--color-background-light);
  border: 1px solid var(--color-border);
  box-shadow:
    0 2px 3px rgba(0, 0, 0, 0.1),
    0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px 0 4px 4px;
  position: absolute;
  right: 0;
  top: 100%;
  width: 150px;
  z-index: 10;
}
.p-datatable-header .export-btns .btn {
  border: none;
  border-radius: 0;
  margin: 2px 0;
  padding: 8px 15px;
  text-align: left;
  width: 100%;
}
.export-btns .btn.btn-link + .btn.btn-link {
  margin-top: 10px;
}
.revealed-filters .btn {
  background-color: #fff;
}
.revealed-filters::after {
  background: var(--color-background-light);
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 100%;
}
.action-btns-group.open-dropdown::after {
  background: #f5f5f5;
  bottom: -1px;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
}
.kuba-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  z-index: 10001;
}
.kuba-loader > span {
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 5px;
  overflow: hidden;
  background: var(--color-primary-500);
  background: -moz-radial-gradient(
    circle at top left,
    ellipse cover,
    #f1b434 0%,
    #91268f 50%,
    #f1b434 99%
  );
  background: -webkit-radial-gradient(
    circle at top left,
    ellipse cover,
    #f1b434 0%,
    #91268f 50%,
    #f1b434 99%
  );
  background: radial-gradient(
    ellipse at top left,
    #f1b434 0%,
    #91268f 50%,
    #f1b434 99%
  );
  background-size: 300% 300%;
  -webkit-animation: gradient-loader 2s ease infinite;
  -moz-animation: gradient-loader 2s ease infinite;
  animation: gradient-loader 2s ease infinite;
}
.kuba-loader > span > span {
  width: 60px;
  height: 20px;
  border-radius: 5px;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.kuba-loader > span > span:first-child {
  top: 20px;
}
.kuba-loader > span > span:last-child {
  top: 60px;
}
@-webkit-keyframes gradient-loader {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 51% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@-moz-keyframes gradient-loader {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 51% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@keyframes gradient-loader {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 51% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
.progress-overlay {
  background-color: rgba(255, 255, 255, 0.75);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
}
.kb-progress-bar {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
.kb-progress-bar .kb-progress {
  width: 14px;
  height: 5px;
  top: 0;
  left: 0;
  border-radius: 3px;
  position: absolute;
  background: var(--color-primary-500);
  -webkit-animation: progress 1.8s linear infinite;
  -moz-animation: progress 1.8s linear infinite;
  animation: progress 1.8s linear infinite;
}
@-webkit-keyframes progress {
  0% {
    width: 10px;
    left: 0;
  }
  25% {
    width: 20%;
    left: 40%;
  }
  50% {
    width: 10px;
    left: calc(100% - 10px);
  }
  75% {
    width: 20%;
    left: 40%;
  }
  100% {
    width: 10px;
    left: 0;
  }
}
@-moz-keyframes progress {
  0% {
    width: 10px;
    left: 0;
  }
  25% {
    width: 20%;
    left: 40%;
  }
  50% {
    width: 10px;
    left: calc(100% - 10px);
  }
  75% {
    width: 20%;
    left: 40%;
  }
  100% {
    width: 10px;
    left: 0;
  }
}
@keyframes progress {
  0% {
    width: 10px;
    left: 0;
  }
  25% {
    width: 20%;
    left: 40%;
  }
  50% {
    width: 10px;
    left: calc(100% - 10px);
  }
  75% {
    width: 20%;
    left: 40%;
  }
  100% {
    width: 10px;
    left: 0;
  }
}
.container {
  min-width: 767px;
  max-width: 2000px;
  width: 100%;
}
.alert-fixed {
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  max-width: 750px;
  margin: auto;
}
.top-header {
  background: var(--color-background-light);
  border-bottom: 1px solid #ccc;
}

.top-link-item.help-links a {
  color: #6c757d;
  display: inline-block;
  padding: 0.7143rem;
  line-height: 16px;
}
.top-link-item.help-links a:hover {
  color: #25282b;
}
.top-link-item .dropdown > a {
  color: #6c757d;
  display: flex;
  align-items: center;
  padding: 0.4285 0.8571rem;
  cursor: pointer;
}
.top-link-item .dropdown > a:hover {
  color: #25282b;
}
.top-link-item.logout {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.top-link-item .dropdown-menu {
  padding-top: 0;
  width: 300px;
}
.top-link-item a > .profile-avatar {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  margin: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
  background: var(--color-background-light);
  position: relative;
}
.top-link-item a > .profile-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top-link-item .account > a {
  padding: 0;
}
.global-search {
  float: left;
  border-right: 1px solid #ccc;
}
.global-search .form-control,
.global-search p-dropdown,
.global-search .btn {
  float: left;
  width: auto;
  height: 34px;
  border: none;
  background: #f5f5f5;
  border-radius: 0;
}
.global-search .form-control {
  border-left: 1px solid var(--color-background-dark);
}
.global-search .ui-dropdown {
  border: none;
}
.global-search .ui-dropdown label.ui-dropdown-label {
  line-height: 34px;
  padding: 0 10px;
  background: #f5f5f5;
}
.global-search .ui-dropdown .ui-dropdown-trigger {
  line-height: 34px;
  background: #f5f5f5;
}
.global-search .btn {
  padding: 0 10px;
}
.modal-content {
  height: 100%;
  border-radius: 8px;
}
.help-list {
  height: 75vh;
  overflow: auto;
  max-height: 650px;
  min-height: 500px;
}
.accordion-group {
  margin: 15px 0px;
}
.accordion-group .accordion-item:last-child {
  border-bottom: 1px solid var(--color-background-dark);
}
.accordion-header {
  border-top: 1px solid var(--color-background-dark);
  margin: 0;
}
.accordion-header a {
  padding: 10px 0;
  display: block;
  color: #333;
}
.accordion-header a[aria-expanded*="true"] .icon {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}
.accordion-content {
  padding: 0 20px;
  margin-bottom: 10px;
}
.accordion-content li a {
  padding: 3px 0;
  display: block;
  margin-bottom: 5px;
}
.welcome-text {
  color: #6c757d;
  float: left;
  max-width: 360px;
  padding: 5px 0;
  text-align: left;
}
.logos-block {
  background: #fff url(../assets/images/bodybg.jpg) repeat-x scroll left center;
  border-bottom: 1px solid var(--color-background-dark);
  padding: 15px 0;
  min-height: 80px;
  position: relative;
  z-index: 13;
}
.medi3 .logos-block {
  background-color: var(--color-primary-500);
  background-image: none;
  color: #fff;
}
.client-logo > a {
  display: block;
}
.client-logo img {
  max-width: 230px;
  max-height: 80px;
  width: auto;
  height: auto;
}
.kuba-logo {
  text-align: right;
}
.kuba-logo img {
  width: 85px;
}
.kuba-logo img + * {
  margin: 10px 0 0;
}
.fs-xs {
  font-size: 12px !important;
}
.fs-sm {
  font-size: 16px !important;
}
.fs-md {
  font-size: 24px !important;
}
.fs-lg {
  font-size: 32px !important;
}
.fs-xl {
  font-size: 40px !important;
}
.ic {
  display: inline-block;
  vertical-align: middle;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
}
.ic-xs {
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
}
.ic-sm {
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
}
.ic-md {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
}
.ic-lg {
  width: 32px;
  height: 32px;
  font-size: 32px;
  line-height: 32px;
}
.ic-xl {
  width: 40px;
  height: 40px;
  font-size: 40px;
  line-height: 40px;
}
.kb-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 15px;
  z-index: 1000;
  float: left;
  width: 400px;
  padding: 5px 0;
  margin: 7px 0 0;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.kb-dropdown-menu.open {
  display: block;
}
.kb-dropdown-menu .kb-dd-header {
  padding: 4px 10px 8px;
  overflow: hidden;
  border-bottom: 1px solid var(--color-background-dark);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.kb-dropdown-menu .kb-dd-header h5 {
  font-family: "Inter", sans-serif;
  margin: 0;
}
.dd-icon-btn {
  text-decoration: none;
  position: relative;
  color: #777;
  display: inline-flex;
  padding: 0.7143rem;
}
.dd-icon-btn .icon {
  opacity: 0.75;
}
.dd-icon-btn:hover,
.dd-icon-btn:focus {
  color: #25282b;
}
.dd-icon-btn.active {
  background-color: rgba(241, 180, 52, 0.2);
}
.dd-icon-btn.active .icon {
  opacity: 1;
}
.dd-icon-btn.active .label {
  margin-left: 5px;
  display: inline-block;
  vertical-align: text-top;
}
.kb-dropdown.open .dd-icon-btn {
  background: var(--color-background-light);
  color: #25282b;
}
.kb-dropdown.open .dd-icon-btn::after {
  content: "";
  border-width: 0 7px 7px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #adb5bd;
  position: absolute;
  bottom: -7px;
  left: 11px;
  border-style: solid;
  z-index: 1000;
}
.kb-dropdown.open .dd-icon-btn::before {
  content: "";
  border-width: 0 7px 7px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff;
  position: absolute;
  bottom: -8px;
  left: 11px;
  border-style: solid;
  z-index: 1001;
}
.dd-icon-btn + .dropdown-menu {
  width: 300px;
  min-height: 150px;
}
.notification-list .list-item {
  display: block;
  border-bottom: 1px solid var(--color-background-dark);
}
.notification-list .list-item a {
  white-space: normal;
  padding: 8px 10px;
  display: table;
  width: 100%;
}
.notification-list .list-item a > span {
  display: table-cell;
  vertical-align: top;
}
.notification-list .list-item a > span.metadata {
  opacity: 0.65;
  width: 75px;
  padding-left: 10px;
  text-align: right;
}
.notification-list .no-data {
  font-size: 14px;
  color: #adb5bd;
  padding: 60px 0;
}
.dropdown .list-heading {
  background-color: var(--color-background-dark);
  overflow: hidden;
  border-bottom: 1px solid var(--color-background-dark);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.dropdown .list-heading a {
  display: inline-flex;
  align-items: center;
  padding: 0;
  clear: none;
  color: var(--color-primary-500);
}
.dropdown .list-heading a:hover,
.dropdown .list-heading a:focus,
.dropdown .list-heading a:active {
  color: #7d217b;
  background-color: rgba(0, 0, 0, 0);
}
.dropdown .list-heading h5 > small {
  margin-top: 5px;
  display: block;
  line-height: 1.5;
}
.account.dropdown .dropdown-header {
  display: flex;
  align-items: center;
  padding: 0.4285rem 0.2142rem;
  font-size: 1rem;
  color: #343a40;
}
.account.dropdown .dropdown-header > span {
  margin: 0 0.2142rem;
  white-space: normal;
  word-break: break-all;
}
.account.dropdown .dropdown-header .profile-avatar {
  width: 60px;
  height: 60px;
  text-align: center;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: auto;
  margin: 0 0.2142rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
  background: var(--color-background-light);
  position: relative;
}
.account.dropdown .dropdown-header .profile-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.notify-date {
  font-size: 10px;
  color: #6c757d;
}
.main-wrapper {
  background-color: rgba(245, 245, 245, 0.75);
}
html > .main-wrapper {
  background: url(../assets/images/bodybg.jpg) repeat-x 0 3px;
  height: 100%;
  min-height: 100%;
}
.main-wrapper > .container {
  position: relative;
}
.app-sidemenu {
  background: var(--color-background-light);
  height: 100%;
  left: 0;
  position: absolute;
  width: 54px;
  overflow: auto;
  -webkit-transition: width 0.35s ease;
  -moz-transition: width 0.35s ease;
  -ms-transition: width 0.35s ease;
  transition: width 0.35s ease;
  overflow: hidden;
}

.opened .app-sidemenu {
  width: 260px;
}

.app-content {
  background: var(--color-background-dark);
  border-left: 1px solid var(--color-contrast-opacity-30);
  margin-left: 39px;
  min-height: 100vh;
  padding: 0 15px;
  margin-right: -15px;
  position: relative;
  -webkit-transition: margin 0.35s ease;
  -moz-transition: margin 0.35s ease;
  -ms-transition: margin 0.35s ease;
  transition: margin 0.35s ease;
}
.opened .app-content {
  margin-left: 245px;
}
.sidemenu-wrapper {
  background: var(--color-background-light);
  padding: 30px 0;
}
// .link-item {
//   position: relative;
//   overflow: hidden;
// }

// .link-item > a:hover {
// }

// .link-item > a {
//   font-family: "Inter", sans-serif;
//   font-size: 1rem;
//   display: block;
//   padding: 6px 15px;
//   color: #495057;
//   font-weight: 600;
//   width: 100%;
//   text-decoration: none;
//   -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
//   -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
//   transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
// }

// .link-item > a:hover {
//   background-color: #ced4da;
//   color: var(--color-primary-500);
// }
// .link-item > a > span {
//   display: inline-block;
//   vertical-align: middle;
// }
// .link-item > a > span + span {
//   padding: 0 10px;
// }
.link-item > a > .link-item-txt {
  display: none;
}
.opened .link-item > a > .link-item-txt {
  display: inline-block;
}
// .link-item.active > a,
// .link-item.active > a:hover {
//   background-color: #fff;
// }
// .link-item.active > a .icon,
// .link-item.active > a:hover .icon {
//   color: var(--color-primary-500);
// }
// .link-item.active::after {
//   border-bottom: 10px solid rgba(0, 0, 0, 0);
//   border-right: 10px solid #f5f5f5;
//   border-top: 10px solid rgba(0, 0, 0, 0);
//   content: "";
//   height: 0;
//   position: absolute;
//   right: 0;
//   top: 8px;
//   width: 0;
//   z-index: 12;
// }
.panel-body .link-item.active::after {
  border: none;
}
.panel-body .link-item > a .link-item-txt {
  display: inline-block;
}
.content-wrapper {
  padding: 5px 0 30px;
}
.masonry-view {
  margin: 0 -7px;
}
.masonry-view > div {
  width: 33.333333%;
  padding: 7px;
  float: left;
  transition: all 0.5s ease-out;
}
.masonry-view .stamp {
  position: absolute;
}
.masonry-view .stamp1 {
  left: 0;
  top: 0;
}
.dashboard {
  padding-top: 10px;
}
.widget-box {
  background: var(--color-background-light);
  border: 1px solid var(--color-border);
  border-radius: 15px;
  overflow: hidden;
}
.widget-box > .wb-content {
  height: 226px;
  overflow: auto;
  position: relative;
}
.widget-box > .wb-content .chart-wrapper {
  padding: 10px;
}
.widget-box .well {
  margin: 0;
}
.wb-title {
  font-family: "Inter", sans-serif;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wb-title > h4 {
  margin: 10px;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
}
.widget-actions {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.wa-btn {
  color: #9e9e9e;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 5px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.widget-box:hover .wa-btn {
  color: rgba(145, 38, 143, 0.8);
}
.widget-box:hover .wa-btn:hover,
.widget-box:hover .wa-btn:focus,
.widget-box:hover .wa-btn:active {
  color: var(--color-primary-500);
}
.wa-btn:after {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  -webkit-transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
.wa-btn:hover:after {
  transform: translate(-50%, -50%) scale(1);
}
.default-list .list-item,
.default-list-nb .list-item {
  border-bottom: 1px solid #dadada;
  display: table;
  padding: 5px;
  width: 100%;
  table-layout: fixed;
}
.default-list .list-item:last-child,
.default-list-nb .list-item:last-child {
  border-bottom: 0;
}
.default-list .list-item:hover,
.default-list-nb .list-item:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
.default-list .list-item > span:last-child:not(:only-child),
.default-list-nb .list-item > span:last-child:not(:only-child) {
  text-align: right;
}
.default-list-nb + .default-list-nb {
  border-top: 1px solid var(--color-background-dark);
}
.default-list-nb .list-item {
  border: none;
}
.list-item > span {
  display: table-cell;
  padding: 0 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.list-item > span.date-column {
  width: 90px;
}
.list-item > span.count-column {
  width: 60px;
  text-align: right;
}
.no-data {
  color: #6c757d;
  width: 100%;
  display: table;
  height: 100%;
  text-align: center;
}
.no-data > span {
  display: table-cell;
  vertical-align: middle;
}
.no-data > span > span + span {
  display: block;
  padding: 8px 0;
}
.no-data > span .icon {
  opacity: 0.35;
}
.company-details {
  border: 1px solid #f1b434;
  background-color: rgba(241, 180, 52, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}
.medi3 .company-details {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.company-content {
  padding: 10px;
}
.company-image {
  margin: 8px;
}
.company-details .inner-wrapper {
  min-height: 264px;
  max-height: 824px;
  overflow: auto;
  -webkit-transition: height 0.3s ease-out;
  -moz-transition: height 0.3s ease-out;
  transition: height 0.3s ease-out;
}
body .fc-basic-view .fc-body .fc-row {
  min-height: 28px;
}
body .fc button {
  height: 26px;
}
.table-condensed tbody tr td {
  vertical-align: middle;
}
datepicker table {
  width: 100%;
}
body .mydp .btnpicker {
  color: #888;
}
.page-title,
.sub-panel-title {
  font-size: 18px;
  font-weight: 600;
  color: #495057;
  background: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ccc;
  border-image: none;
  border-style: none none solid;
  border-width: medium medium 1px;
  margin-bottom: 30px;
  padding: 0 0 10px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.page-title span,
.sub-panel-title span {
  display: inline-flex;
}
.page-content,
.sub-panel-content {
  padding-bottom: 20px;
}
.page-actions,
.sub-panel-actions {
  text-align: right;
  padding-left: 15px;
  margin-left: auto;
}
.page-actions .btn,
.sub-panel-actions .btn {
  font-family: "Inter", sans-serif;
  vertical-align: top;
}
.page-actions .btn + .btn,
.page-actions .btn + .form-control,
.sub-panel-actions .btn + .btn,
.sub-panel-actions .btn + .form-control {
  margin-left: 5px;
}
.page-actions .form-control,
.sub-panel-actions .form-control {
  width: 160px;
  display: inline-block;
  vertical-align: top;
}
.page-actions p-dropdown,
.sub-panel-actions p-dropdown {
  display: inline-block;
}
.sub-panel-title {
  border: none;
  margin-bottom: 10px;
  background: #f5f5f5;
  padding: 5px 10px;
}
.sub-panel-title .sub-panel-actions {
  display: none;
}
.sub-panel:hover .sub-panel-actions {
  display: inline-block;
}
.sub-panel + .sub-panel > .sub-panel-content {
  border-bottom: 1px solid var(--color-background-dark);
}
.sub-panel-content {
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.sub-panel-content .sub-panel-title {
  color: #6c757d;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px solid var(--color-background-dark);
}
.panel,
.main-content {
  border: none;
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.panel .panel-heading,
.panel .main-heading,
.main-content .panel-heading,
.main-content .main-heading {
  font-family: "Inter", sans-serif;
  font-size: 22px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0 15px;
}
.panel .panel-heading > strong,
.panel .panel-heading > span,
.panel .main-heading > strong,
.panel .main-heading > span,
.main-content .panel-heading > strong,
.main-content .panel-heading > span,
.main-content .main-heading > strong,
.main-content .main-heading > span {
  font-weight: 600;
}
.panel-tabs .nav-pills > li,
.main-content-tabs .nav-pills > li {
  margin-right: 20px;
}
.panel-tabs .nav-pills > li > a,
.main-content-tabs .nav-pills > li > a {
  display: block;
  border-radius: 0;
  color: #6c757d;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  padding: 8px 4px;
  min-width: 30px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.panel-tabs .nav-pills > li > a:hover,
.panel-tabs .nav-pills > li > a:focus,
.panel-tabs .nav-pills > li > a:active,
.main-content-tabs .nav-pills > li > a:hover,
.main-content-tabs .nav-pills > li > a:focus,
.main-content-tabs .nav-pills > li > a:active {
  background-color: rgba(0, 0, 0, 0);
  color: #333;
  border-color: rgba(0, 0, 0, 0.35);
  outline: 0 none;
}
.panel-tabs .nav-pills > li.active > a,
.panel-tabs .nav-pills > li.active a:hover,
.panel-tabs .nav-pills > li.active a:focus,
.main-content-tabs .nav-pills > li.active > a,
.main-content-tabs .nav-pills > li.active a:hover,
.main-content-tabs .nav-pills > li.active a:focus {
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-primary-500);
  border-bottom-color: var(--color-primary-500);
}
.panel-tabs .btn-group,
.main-content-tabs .btn-group {
  position: absolute;
  right: 0;
  top: -30px;
}
.page-title + .panel-tabs,
.page-title + .main-content-tabs {
  margin-top: -30px;
}
.panel-tabs.scroll,
.main-content-tabs.scroll {
  position: relative;
}
.panel-tabs.scroll nav,
.main-content-tabs.scroll nav {
  overflow: auto;
}
.panel-tabs.scroll:after,
.main-content-tabs.scroll:after {
  content: "";
  width: 60px;
  height: 42px;
  position: absolute;
  right: 0;
  top: 0;
  background: -moz-linear-gradient(
    left,
    rgba(245, 245, 245, 0) 0%,
    rgb(245, 245, 245) 83%,
    rgb(245, 245, 245) 99%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(245, 245, 245, 0) 0%,
    rgb(245, 245, 245) 83%,
    rgb(245, 245, 245) 99%
  );
  background: linear-gradient(
    to right,
    rgba(245, 245, 245, 0) 0%,
    rgb(245, 245, 245) 83%,
    rgb(245, 245, 245) 99%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00f5f5f5", endColorstr="#f5f5f5", GradientType=1);
}
.panel-tabs.scroll.scrolling:before,
.main-content-tabs.scroll.scrolling:before {
  content: "";
  width: 60px;
  height: 42px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  background: -moz-linear-gradient(
    left,
    rgb(245, 245, 245) 0%,
    rgb(245, 245, 245) 30%,
    rgba(245, 245, 245, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgb(245, 245, 245) 0%,
    rgb(245, 245, 245) 30%,
    rgba(245, 245, 245, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgb(245, 245, 245) 0%,
    rgb(245, 245, 245) 30%,
    rgba(245, 245, 245, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5f5", endColorstr="#00f5f5f5", GradientType=1);
}
.panel-tabs + .card,
.main-content-tabs + .card {
  border-top-left-radius: 0;
}
.panel-body,
.main-content-body {
  background: var(--color-background-light);
  border: 1px solid var(--color-border);
}
.panel-body .panel-tabs > .nav-pills li a,
.main-content-body .panel-tabs > .nav-pills li a {
  border-bottom: none;
  padding: 0.75em 1em;
}
.panel-body .panel-tabs > .nav-pills li.active a:after,
.main-content-body .panel-tabs > .nav-pills li.active a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  z-index: 10;
  background-color: var(--color-primary-500);
}
.panel-body .panel-tabs nav,
.main-content-body .panel-tabs nav {
  position: relative;
}
.panel-body .panel-tabs nav > .nav-pills,
.main-content-body .panel-tabs nav > .nav-pills {
  background: var(--color-background-light);
  margin: 0 0 20px;
}
.panel-body .panel-tabs nav > .nav-pills > li.active > a,
.panel-body .panel-tabs nav > .nav-pills > li.active a:hover,
.panel-body .panel-tabs nav > .nav-pills > li.active a:focus,
.main-content-body .panel-tabs nav > .nav-pills > li.active > a,
.main-content-body .panel-tabs nav > .nav-pills > li.active a:hover,
.main-content-body .panel-tabs nav > .nav-pills > li.active a:focus {
  background-color: rgba(145, 38, 143, 0.05);
  box-shadow: 0 1px #91268f;
}
.panel-body .panel-tabs nav > .nav-pills > li.active::after,
.main-content-body .panel-tabs nav > .nav-pills > li.active::after {
  border-top: 8px solid #91268f;
  border-left: 8px solid rgba(0, 0, 0, 0);
  border-right: 8px solid rgba(0, 0, 0, 0);
  bottom: -9px;
  content: "";
  left: 50%;
  margin-left: -8px;
  position: absolute;
  z-index: 10;
}
.panel-body .panel-tabs nav > .nav-pills > li.active::before,
.main-content-body .panel-tabs nav > .nav-pills > li.active::before {
  border-top: 8px solid rgba(255, 255, 255, 0.9);
  border-left: 8px solid rgba(0, 0, 0, 0);
  border-right: 8px solid rgba(0, 0, 0, 0);
  bottom: -8px;
  content: "";
  left: 50%;
  margin-left: -8px;
  position: absolute;
  z-index: 11;
}
.panel-body .panel-tabs nav > .nav-pills > li a,
.main-content-body .panel-tabs nav > .nav-pills > li a {
  border-bottom: none;
  padding: 0.75em 1em;
}
.panel-body .panel-tabs .nav-pills,
.main-content-body .panel-tabs .nav-pills {
  border-bottom: 1px solid var(--color-background-dark);
}
.panel-body .panel-tabs.tabs-vertical .nav-pills,
.main-content-body .panel-tabs.tabs-vertical .nav-pills {
  border-bottom: none;
}
.panel-body .panel-tabs.tabs-vertical .nav-pills li,
.main-content-body .panel-tabs.tabs-vertical .nav-pills li {
  width: 100%;
}
.panel-body .panel-tabs.tabs-vertical .nav-pills li a,
.main-content-body .panel-tabs.tabs-vertical .nav-pills li a {
  border-bottom: none;
}
.panel-body .panel-tabs.tabs-vertical .nav-pills li + li,
.main-content-body .panel-tabs.tabs-vertical .nav-pills li + li {
  margin: 0;
}
.panel-body .panel-tabs.tabs-left .nav.nav-pills,
.main-content-body .panel-tabs.tabs-left .nav.nav-pills {
  border-right: 1px solid var(--color-background-dark);
}
.panel-body .panel-tabs.tabs-left .nav.nav-pills li.active a:after,
.main-content-body .panel-tabs.tabs-left .nav.nav-pills li.active a:after {
  content: "";
  position: absolute;
  top: 0;
  right: -1px;
  width: 2px;
  height: 100%;
  z-index: 10;
  background-color: var(--color-primary-500);
}
.panel-body .panel-tabs.tabs-right .nav.nav-pills,
.main-content-body .panel-tabs.tabs-right .nav.nav-pills {
  border-left: 1px solid var(--color-background-dark);
}
.panel-body .panel-tabs.tabs-right .nav.nav-pills li.active a:after,
.main-content-body .panel-tabs.tabs-right .nav.nav-pills li.active a:after {
  content: "";
  position: absolute;
  top: 0;
  left: -1px;
  width: 2px;
  height: 100%;
  z-index: 10;
  background-color: var(--color-primary-500);
}
.panel-tab-inner {
  display: block;
}
.properties-wrapper {
  max-height: 1000px;
  overflow-y: auto;
  overflow-x: hidden;
}
.required {
  color: #ee583f;
}
.table-view table table {
  margin: 0;
}
.action-btns-wrapper {
  border-top: 1px solid #ccc;
  padding-top: 10px;
  margin-top: 10px;
}
.p-datatable-summary .action-btns-wrapper,
.p-datatable-header .action-btns-wrapper {
  border-top: none;
  margin: 0;
  padding: 0;
}
.action-btns-wrapper + .table-view {
  margin-top: 20px;
}
p-footer .table-view {
  margin-top: 10px;
  max-height: 200px;
  overflow: auto;
}
.export-btn-group .btn-link {
  color: #333;
}
.action-label {
  color: #6c757d;
  font-weight: normal;
  padding-right: 10px;
}
.filter-controls-wrapper {
  border-bottom: 1px solid var(--color-background-dark);
  background-color: #fff;
  padding: 1rem 1rem 0;
}
.filter-controls-wrapper .ui-column-filter {
  margin-top: 0;
}
.filter-controls {
  background: var(--color-background-light);
  border: 1px solid #e0e0e0;
  margin-bottom: 35px;
  padding: 10px 10px 0;
  border-radius: 5px;
  position: relative;
}
.filter-controls .form-group > label {
  display: block;
  margin-top: -4px;
}
.filter-controls + .action-btns-wrapper {
  border: none;
  padding: 0;
  margin: 0 0 10px 0;
}
.form-table .checkbox {
  line-height: normal;
  margin: 0;
}
.form-table .checkbox input[type="checkbox"] {
  margin: 5px;
  position: static;
}
.table-collapse {
  position: relative;
}
.table-collapse > td > .table {
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid #bababa;
}
.table-collapse::after {
  border-bottom: 7px solid var(--color-background-dark);
  border-left: 7px solid rgba(0, 0, 0, 0);
  border-right: 7px solid rgba(0, 0, 0, 0);
  bottom: 100%;
  content: "";
  left: 12px;
  position: absolute;
}
.table tr.table-collapse > td {
  background: #f5f5f5;
  border-bottom: 1px solid var(--color-background-dark);
  padding: 0;
}
.table > thead > tr > th {
  background-color: var(--color-background-dark);
}
label {
  font-weight: 500;
}
.form-group,
.form-group-50_50,
.form-group-25_75,
.form-group-75_25 {
  width: 100%;
  max-width: 500px;
}
p-dialog .form-group,
p-dialog .form-group-50_50,
p-dialog .form-group-25_75,
p-dialog .form-group-75_25 {
  width: 100%;
  max-width: 500px;
}
.form-group-50_50,
.form-group-25_75,
.form-group-75_25 {
  display: flex;
  align-items: flex-start;
}
.form-group-50_50 .form-group {
  width: 50%;
  max-width: 250px;
}
.form-group-50_50 .form-group + .form-group {
  margin-left: 10px;
}
.form-group-25_75 .form-group:first-child,
.form-group-75_25 .form-group + .form-group {
  width: 95px;
}
.form-group-25_75 .form-group + .form-group,
.form-group-75_25 .form-group:first-child {
  margin-left: 7px;
}
.form-group-75_25 .form-group + .form-group {
  margin-left: 7px;
}
.form-group-75_25 .form-group:first-child {
  margin-left: 0;
}
.input-group-50_50 input {
  width: 50%;
  max-width: 250px;
}
.input-group-50_50 input + input {
  margin-left: 7px;
}
.form-inline + .table-view {
  margin-top: 15px;
}
.form-inline .form-group {
  width: auto;
}
.form-inline .form-group > .control-label + .form-control {
  margin-left: 5px;
}
.form-inline .form-group + .form-group {
  margin-left: 20px;
}
form + .table-view {
  margin-top: 20px;
}
.table-view + div {
  margin-top: 20px;
}
.form-control {
  max-width: 500px;
}
.form-data {
  margin-bottom: 15px;
}
.form-data > label {
  display: inline-block;
}
.form-data > label:first-child {
  width: 150px;
}
[readonly].form-control {
  background: var(--color-background-dark);
}
textarea.form-control,
.p-inputtextarea {
  min-height: 82px;
  max-height: 130px;
  min-width: 200px;
  max-width: 100%;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
p-checkbox + p-checkbox,
p-radiobutton + p-radiobutton {
  margin-left: 1rem;
}
body .input-btn-group {
  display: flex;
}
body .input-btn-group p-dropdown,
body .input-btn-group p-multiselect {
  flex: 1;
  max-width: calc(100% - 45px);
}
body .input-btn-group p-dropdown ~ .btn,
body .input-btn-group p-dropdown ~ p-dropdown,
body .input-btn-group p-multiselect ~ .btn,
body .input-btn-group p-dropdown ~ input {
  margin-left: 5px;
}
.phone-field {
  display: flex;
}
.phone-field > p-dropdown {
  flex: 0 auto;
}
.phone-field > .p-inputtext {
  flex: 1;
}
.phone-field > p-dropdown ~ .p-inputtext {
  max-width: 100%;
  margin-left: 10px;
}
.phone-field > p-dropdown ~ .btn {
  margin-left: 10px;
}
.phone-field > p-dropdown + .btn + .p-inputtext {
  max-width: 100%;
  margin-left: 10px;
}
.phone-field > .p-inputtext:not(.ui-dropdown-filter):first-child {
  width: calc(40% - 10px);
  max-width: calc(40% - 10px);
}
.phone-field > .p-inputtext + .p-inputtext {
  width: 60%;
  max-width: 60%;
  margin-left: 10px;
}
.btn-group > .btn + .dropdown-toggle {
  line-height: 18px;
}
.data-action-btn {
  color: var(--color-contrast-light);
}
.inline-list {
  display: inline-block;
  padding: 15px 50px;
}
.inline-list + .inline-list {
  border-left: 1px dotted var(--color-contrast-light);
}
.image-preview {
  border: 1px solid var(--color-contrast-light);
  border-radius: 5px;
  display: inline-block;
  margin: 0;
  max-width: 400px;
  overflow: hidden;
}
.node-menu .node-menu-content {
  z-index: 10;
}
.ui-tree.ui-widget-content {
  border: none !important;
  border-color: rgba(0, 0, 0, 0) !important;
  width: auto;
}
.ui-tree .ui-treenode {
  font-size: 14px !important;
}
.ui-tree .ui-treenode .ui-treenode-content .ui-treenode-icon {
  color: #ebb644 !important;
}
.ui-menu.ui-menu-dynamic {
  position: fixed !important;
}
.go-back-btn {
  width: 16px;
  height: 16px;
  overflow: hidden;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0);
  border: none;
}
.go-back-btn .icon {
  display: block;
}
.detail-view {
  border-left: 1px solid var(--color-background-dark);
  border-right: 1px solid var(--color-background-dark);
}
.loading-overlay {
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  z-index: 10000;
}
.sk-three-bounce {
  margin: 40px auto;
  width: 80px;
  text-align: center;
}
.sk-three-bounce .sk-child {
  width: 20px;
  height: 20px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}
.sk-three-bounce .sk-bounce1 {
  animation-delay: -0.32s;
}
.sk-three-bounce .sk-bounce2 {
  animation-delay: -0.16s;
}
@keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
}
.tree-node-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
span.search-tree-nodes {
  float: right;
}
.node-wrapper .ecpl-node-icon {
  flex: 0 0 25px;
}
.node-content-wrapper {
  color: var(--color-contrast-light);
  width: 100%;
}
.node-content-wrapper > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
tree-node-expander {
  width: 16px;
}
.tree-view .folder-collapsed,
.tree-view .folder-expanded,
.node-content-wrapper-active .folder-expanded,
.node-content-wrapper.node-content-wrapper-active:hover .folder-expanded,
.node-content-wrapper-active.node-content-wrapper-focused .folder-expanded {
  color: #d6b36c;
}
.tree-view .tree-node-expanded > tree-node-wrapper .folder-collapsed,
.tree-view .tree-node-collapsed > tree-node-wrapper .folder-expanded,
.tree-children .folder-expanded,
.tree-view .tree-node-leaf .folder-expanded {
  display: none;
}
.tree-view .tree-node-expanded > tree-node-wrapper .folder-expanded,
.tree-view .tree-node-collapsed > tree-node-wrapper .folder-collapsed {
  display: inline-block;
}
body .node-content-wrapper:hover {
  background-color: var(--color-primary-opacity-10);
  color: var(--color-primary-500);
  box-shadow: none !important;
}
body .node-content-wrapper-active,
body .node-content-wrapper.node-content-wrapper-active:hover,
body .node-content-wrapper-active.node-content-wrapper-focused {
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-primary-500);
  box-shadow: none !important;
}
body .node-content-wrapper-active {
  font-weight: bold;
}
body .node-content-wrapper .node-content-wrapper-focused {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.treeNode {
  font-size: 14px;
}
.tree-grid .treeNode {
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
tree-root .angular-tree-component {
  cursor: default;
}
.treeNodeRename {
  font-size: 11px;
  font-weight: 500;
}
.tree-grid tree-node {
  max-width: 280px;
  width: 50%;
  display: inline-block;
  margin-bottom: 20px;
  padding: 0 10px;
}
.tree-grid .tree-children {
  padding: 0;
}
.tree-grid
  .angular-tree-component
  > tree-node-collection
  > div
  > tree-node
  > .tree-node-level-1
  > tree-node-children {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: var(--color-background-light);
  z-index: 999;
  box-shadow: 0 8px 10px var(--color-contrast-opacity-20);
}
.tree-grid
  .angular-tree-component
  > tree-node-collection
  > div
  > tree-node
  > .tree-node-level-1
  > tree-node-children
  > .tree-children {
  border: 1px solidvar(--color-contrast-light);
  max-height: 280px;
  overflow: auto;
}
.tree-grid .tree-children tree-node {
  padding: 0 4px;
  width: 100%;
  margin-bottom: 5px;
}
.tree-grid .tree-node-level-1 {
  border: 1px solid var(--color-contrast-light);
  border-radius: 3px;
  padding: 5px;
}
.tree-grid .tree-node {
  position: relative;
}
.tree-grid tree-node-expander {
  position: absolute;
  width: 30px;
  height: 100%;
  right: 0;
  top: 0;
  cursor: pointer;
}
.tree-grid .toggle-children-wrapper {
  padding: 8px 10px;
  display: block;
  border-left: 1px solid var(--color-contrast-light);
  height: 100%;
  background: var(--color-background-light);
  border-radius: 0 5px 5px 0;
}
.tree-grid tree-viewport {
  overflow: visible;
}
tree-node-content {
  cursor: pointer;
}
.tree-grid tree-node-content > span {
  float: left;
}
.tree-grid tree-node-content > span + span {
  width: calc(100% - 25px);
  margin-left: 5px;
}
@media screen and (max-width: 1080px) {
  .masonry-view > div {
    width: 50%;
  }
}
@media screen and (max-width: 991px) {
  .app-sidemenu .sidemenu-wrapper {
    max-height: 100vh;
    overflow: auto;
  }
}
@media screen and (max-width: 767px) {
  .masonry-view > div {
    width: 100%;
  }
}
td.col-button {
  text-align: center;
}
td.col-button .btn-icon {
  color: var(--color-primary-500);
  text-decoration: none;
}
tr:hover > td > span > .icon.icon-pencil-tip,
tr:hover > td > span > .icon.icon-add {
  color: #1e9b1e;
}
tr:hover > td > span > .icon.icon-trash,
tr:hover > td > span > .icon.icon-delete {
  color: #e34a31;
}
table td .btn + .btn {
  margin-left: 8px;
}
.footer-data {
  display: table;
  width: 100%;
}
.footer-data > div {
  display: table-cell;
  vertical-align: middle;
}
.symbol-desc-items > span {
  padding: 0 5px;
  display: inline-block;
}
.symbol-desc-title {
  padding: 0 10px;
  font-weight: 500;
  color: #6c757d;
  display: inline-block;
  vertical-align: middle;
}
.symbol-desc-items {
  display: inline-block;
  padding: 0 10px;
  vertical-align: middle;
}
.symbol-desc-items > span {
  padding: 5px 10px;
  float: left;
}
.symbol-desc-items > span + span {
  border-left: 1px solid var(--color-border);
}
.symbol-desc-items > span > span,
.symbol-desc-items > span > i {
  display: inline-block;
  vertical-align: middle;
}
.symbol-desc-items > span > i {
  margin-right: 5px;
}
.text-accent-1,
.icon.text-accent-1,
.text-accent-1 > i,
.new-deadline,
.icon-new-deadline {
  color: #0ab7e2;
}
.text-accent-2,
.icon.text-accent-2,
.text-accent-2 > i,
.far-deadline,
.icon-far-deadline {
  color: #4f49b2;
}
.text-accent-3,
.icon.text-accent-3,
.text-accent-3 > i,
.clear-deadline,
.icon-clear-deadline {
  color: #393;
}
.text-accent-4,
.icon.text-accent-4,
.text-accent-4 > i,
.near-deadline,
.icon-near-deadline {
  color: #f7941d;
}
.text-accent-5,
.icon.text-accent-5,
.text-accent-5 > i,
.over-deadline,
.icon-over-deadline {
  color: #e34a31;
}
.error-msg {
  color: #e34a31;
  font-weight: bold;
  margin: 5px 0;
}
.upload-file-container {
  margin-bottom: 15px;
}
.upload-file-container input {
  display: none;
}
.upload-file-container .drop-container {
  border: 2px solid var(--color-border);
  padding: 30px;
  color: var(--color-contrast-dark);
  text-align: center;
  border-radius: 3px;
  background: var(--color-background-light);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  -webkit-transition:
    border-color 0.25s ease,
    background-color 0.25s ease,
    transform 0.25s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  -moz-transition:
    border-color 0.25s ease,
    background-color 0.25s ease,
    transform 0.25s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition:
    border-color 0.25s ease,
    background-color 0.25s ease,
    transform 0.25s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.upload-file-container .drop-container span {
  -webkit-transition: all 0.25s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  -moz-transition: all 0.25s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: all 0.25s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.upload-file-container .drop-container.dragging {
  color: var(--color-primary-500);
  background: var(--color-primary-opacity-20);
  border-color: var(--color-border);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  -webkit-transform: scale(1.025);
  -moz-transform: scale(1.025);
  transform: scale(1.025);
}
.upload-file-container .drop-container.dragging span {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}
.upload-file-container .upload-actions {
  margin-bottom: 10px;
}
.upload-file-container .upload-items .upload-item {
  width: 100%;
  display: block;
  margin-top: 20px;
}
.upload-file-container .upload-items .upload-item .filename {
  display: block;
  padding: 0 0 5px 5px;
  color: var(--color-contrast-dark);
}
.upload-file-container .upload-items .upload-item .progress {
  display: block;
  position: relative;
  width: 100%;
  height: 15px;
  border: 1px solid var(--color-primary-500);
  border-radius: 4px;
}
.upload-file-container .upload-items .upload-item .progress .bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  background: var(--color-primary-500);
}
.tag-item {
  display: inline-block;
  margin-left: 2px;
  margin-bottom: 5px;
  cursor: pointer;
}
.tag-item span {
  padding: 2px 8px;
  background: rgba(241, 180, 52, 0.05);
  display: block;
  border: 1px solid rgba(241, 180, 52, 0.5);
  border-radius: 3px;
  color: #b37d0c;
}
.tag-item span:hover {
  color: #6b4b07;
  border-color: #f1b434;
  background: var(--color-background-light);
}
.fc .fc-toolbar-title {
  font-size: 16px;
  margin-top: 4px;
  padding-left: 10px;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
  padding: 6px;
  overflow: auto;
}
.fc-day-grid-event {
  cursor: pointer;
}
.fc-day-grid-event .fc-time {
  display: none;
}
tabset tab {
  margin-top: 15px;
}
body .mydp * {
  font-family: "Inter", sans-serif;
}
body .mydp {
  display: block;
}
.no-files {
  border: 2px dashed var(--color-border);
  background-color: #f8f9fa;
  width: 100%;
  display: table;
  min-height: 160px;
  border-radius: 4px;
  color: var(--color-contrast-light);
}
.is-drop-over .no-files {
  border-color: rgba(0, 0, 0, 0);
  background-color: #beeaff;
}
.no-files > span {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.no-files .files-drop-icon {
  width: 130px;
  height: 55px;
  background: url(../assets/images/files-drop-preview.png) no-repeat 0 0;
  display: inline-block;
  margin-bottom: 10px;
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
}
.is-drop-over .no-files .files-drop-icon {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
}
.loader {
  border: 16px solid var(--color-border);
  border-top: 16px solid var(color-primary-500);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
body .fc-event {
  text-indent: -99999px;
  width: 4px;
  height: 4px;
  overflow: hidden;
  border-radius: 4px;
  padding: 0;
  display: inline-block;
}
table td.fc-event-container {
  text-align: right;
  line-height: 1;
}
.icon-filter {
  color: var(--color-primary-opacity-70);
  color: var(--color-primary-500);
}
.icon-task-not-started {
  color: gray;
}
.icon-task-started {
  color: #f7941d;
}
td.col-btn-right .btn-icon {
  color: var(--color-primary-opacity-70);
  color: var(--color-primary-500);

  text-decoration: none;
}
.ngx-pagination {
  padding-left: 0;
  display: inline-block;
}
body .ngx-pagination .current {
  background-color: var(--color-primary-500);
  background-color: var(--color-primary-500);
} /*# sourceMappingURL=style.min.css.map */
