@use "sass:color";
@use "./functions" as functions;

@mixin generate-color-themes(
  $primary-color,
  $secondary-color,
  $background-color-light,
  $background-color-dark,
  $border-color,
  $contrast-color
) {
  :root {
    --color-primary-50: #{color.scale($primary-color, $lightness: 40%)};
    --on-color-primary-50: #{functions.get-contrast-color(
        color.scale($primary-color, $lightness: 40%)
      )};
    --color-primary-100: #{color.scale($primary-color, $lightness: 30%)};
    --on-color-primary-100: #{functions.get-contrast-color(
        color.scale($primary-color, $lightness: 30%)
      )};
    --color-primary-200: #{color.scale($primary-color, $lightness: 20%)};
    --on-color-primary-200: #{functions.get-contrast-color(
        color.scale($primary-color, $lightness: 20%)
      )};
    --color-primary-300: #{color.scale($primary-color, $lightness: 10%)};
    --on-color-primary-300: #{functions.get-contrast-color(
        color.scale($primary-color, $lightness: 10%)
      )};
    --color-primary-400: #{color.scale($primary-color, $lightness: 5%)};
    --on-color-primary-400: #{functions.get-contrast-color(
        color.scale($primary-color, $lightness: 5%)
      )};
    --color-primary-500: #{$primary-color};
    --on-color-primary-500: #{functions.get-contrast-color($primary-color)};
    --color-primary-600: #{color.scale($primary-color, $lightness: -10%)};
    --on-color-primary-600: #{functions.get-contrast-color(
        color.scale($primary-color, $lightness: -10%)
      )};
    --color-primary-700: #{color.scale($primary-color, $lightness: -20%)};
    --on-color-primary-700: #{functions.get-contrast-color(
        color.scale($primary-color, $lightness: -20%)
      )};
    --color-primary-800: #{color.scale($primary-color, $lightness: -30%)};
    --on-color-primary-800: #{functions.get-contrast-color(
        color.scale($primary-color, $lightness: -30%)
      )};
    --color-primary-900: #{color.scale($primary-color, $lightness: -40%)};
    --on-color-primary-900: #{functions.get-contrast-color(
        color.scale($primary-color, $lightness: -40%)
      )};

    --color-secondary-50: #{color.scale($secondary-color, $lightness: 40%)};
    --on-color-secondary-50: #{functions.get-contrast-color(
        color.scale($secondary-color, $lightness: 40%)
      )};
    --color-secondary-100: #{color.scale($secondary-color, $lightness: 30%)};
    --on-color-secondary-100: #{functions.get-contrast-color(
        color.scale($secondary-color, $lightness: 30%)
      )};
    --color-secondary-200: #{color.scale($secondary-color, $lightness: 20%)};
    --on-color-secondary-200: #{functions.get-contrast-color(
        color.scale($secondary-color, $lightness: 20%)
      )};
    --color-secondary-300: #{color.scale($secondary-color, $lightness: 10%)};
    --on-color-secondary-300: #{functions.get-contrast-color(
        color.scale($secondary-color, $lightness: 10%)
      )};
    --color-secondary-400: #{color.scale($secondary-color, $lightness: 5%)};
    --on-color-secondary-400: #{functions.get-contrast-color(
        color.scale($secondary-color, $lightness: 5%)
      )};
    --color-secondary-500: #{$secondary-color};
    --on-color-secondary-500: #{functions.get-contrast-color($secondary-color)};
    --color-secondary-600: #{color.scale($secondary-color, $lightness: -10%)};
    --on-color-secondary-600: #{functions.get-contrast-color(
        color.scale($secondary-color, $lightness: -10%)
      )};
    --color-secondary-700: #{color.scale($secondary-color, $lightness: -20%)};
    --on-color-secondary-700: #{functions.get-contrast-color(
        color.scale($secondary-color, $lightness: -20%)
      )};
    --color-secondary-800: #{color.scale($secondary-color, $lightness: -30%)};
    --on-color-secondary-800: #{functions.get-contrast-color(
        color.scale($secondary-color, $lightness: -30%)
      )};
    --color-secondary-900: #{color.scale($secondary-color, $lightness: -40%)};
    --on-color-secondary-900: #{functions.get-contrast-color(
        color.scale($secondary-color, $lightness: -40%)
      )};

    --color-background-light: #{$background-color-light};
    --color-background-dark: #{$background-color-dark};

    --color-border: #{$border-color};

    --color-contrast-light: #{color.scale($contrast-color, $lightness: 10%)};
    --color-contrast-dark: #{$contrast-color};

    --color-background-opacity: #{color.scale(
        $contrast-color,
        $lightness: 10%,
        $alpha: 0.3%
      )};

    @include generate-opacity-variables($primary-color, "primary");
    @include generate-opacity-variables($secondary-color, "secondary");
    @include generate-opacity-variables($contrast-color, "contrast");
  }
}

// #{0.1 * $i}

@mixin generate-opacity-variables(
  $color,
  $name,
  $opacities: (0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9)
) {
  @each $opacity in $opacities {
    // Calculate the RGBA color
    $rgba-color: rgba(red($color), green($color), blue($color), $opacity);

    // Output the class for the color with the specific opacity
    --color-#{$name}-opacity-#{$opacity * 100}: #{$rgba-color};
  }
}
