/*!
 * Icon Font KUBA icons
 */

@font-face {
    font-family: "KUBA icons";
    src: url("../fonts/KUBA-icons.eot");
    src: url("../fonts/KUBA-icons.eot?#iefix") format("embedded-opentype"), url("../fonts/KUBA-icons.woff2") format("woff2"), url("../fonts/KUBA-icons.woff") format("woff"), url("../fonts/KUBA-icons.ttf") format("truetype"), url("../fonts/KUBA-icons.svg#KUBA-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "KUBA-icons";
        src: url("../fonts/KUBA-icons.svg#KUBA-icons") format("svg");
    }
}

[data-icon]:before {
    content: attr(data-icon);
}

.icon-profile:before,
.icon-view-detail:before,
.icon-expand-arrow:before,
.icon-edit-content:before,
.icon-046:before,
.icon-020:before,
.icon-021:before,
.icon-product:before,
.icon-014:before,
.icon-filing-cabinet:before,
.icon-019:before,
.icon-018:before,
.icon-016:before,
.icon-contract-job:before,
.icon-013:before,
.icon-details-pane:before,
.icon-truck:before,
.icon-activity-log:before,
.icon-page-overview-2:before,
.icon-page:before,
.icon-012:before,
.icon-edit:before,
.icon-view-content:before,
.icon-checkmark:before,
.icon-reset:before,
.icon-005:before,
.icon-online-support:before,
.icon-message:before,
.icon-help:before,
.icon-063:before,
.icon-document:before,
.icon-documents:before,
.icon-002:before,
.icon-040:before,
.icon-high-priority:before,
.icon-003:before,
.icon-search:before,
.icon-marker:before,
.icon-menu-2:before,
.icon-forward:before,
.icon-008:before,
.icon-notification:before,
.icon-006:before,
.icon-007:before,
.icon-star:before,
.icon-new-deadline:before,
.icon-038:before,
.icon-010:before,
.icon-011:before,
.icon-009:before,
.icon-back:before,
.icon-015:before,
.icon-032:before,
.icon-033:before,
.icon-034:before,
.icon-trash:before,
.icon-035:before,
.icon-025:before,
.icon-004:before,
.icon-036:before,
.icon-toggle-off:before,
.icon-toggle-on:before,
.icon-037:before,
.icon-026:before,
.icon-near-deadline:before,
.icon-030:before,
.icon-far-deadline:before,
.icon-over-deadline:before,
.icon-039:before,
.icon-sort-down:before,
.icon-high-importance:before,
.icon-027:before,
.icon-timer:before,
.icon-028:before,
.icon-029:before,
.icon-bar-chart:before,
.icon-area-chart:before,
.icon-collapse-arrow:before,
.icon-multiply:before,
.icon-planner:before,
.icon-picture:before,
.icon-play:before,
.icon-circled-play:before,
.icon-info:before,
.icon-049:before,
.icon-thermometer-automation:before,
.icon-external-link-squared:before,
.icon-copy-to-clipboard:before,
.icon-print:before,
.icon-paste:before,
.icon-001:before,
.icon-pencil-tip:before,
.icon-delete:before,
.icon-clear-deadline:before,
.icon-preview-pane:before,
.icon-folder:before,
.icon-open:before,
.icon-file:before,
.icon-filter:before,
.icon-send-file:before,
.icon-checklist:before,
.icon-trash-can:before,
.icon-add-file:before,
.icon-image-file:before,
.icon-video-file:before,
.icon-audio-file:before,
.icon-attach:before,
.icon-documents-o:before,
.icon-document-o:before,
.icon-send-file-o:before,
.icon-file-o:before,
.icon-image-file-o:before,
.icon-video-file-o:before,
.icon-048:before,
.icon-cancel:before,
.icon-066:before,
.icon-filter-o:before,
.icon-clear-filters:before,
.icon-clear-filters-o:before,
.icon-activity-history:before,
.icon-find-user-male:before,
.icon-user-rights:before,
.icon-plus:before,
.icon-front-desk:before,
.icon-flow-chart:before,
.icon-combo-chart:before,
.icon-chart:before,
.icon-031:before,
.icon-041:before,
.icon-042:before,
.icon-017:before,
.icon-food-plate:before,
.icon-044:before,
.icon-suitcase:before,
.icon-047:before,
.icon-light-automation:before,
.icon-043:before,
.icon-050:before,
.icon-051:before,
.icon-not-applicable:before,
.icon-services:before,
.icon-in:before,
.icon-out:before,
.icon-add:before,
.icon-checklist-property:before,
.icon-checklist-property-edit:before,
.icon-022:before,
.icon-023:before,
.icon-024:before,
.icon-download-from-cloud:before,
.icon-download-from-the-cloud:before,
.icon-download:before,
.icon-external-link:before,
.icon-external-link-2:before,
.icon-info-o:before,
.icon-053:before,
.icon-054:before,
.icon-056:before,
.icon-052:before,
.icon-059:before,
.icon-male-user:before,
.icon-shopping-cart:before,
.icon-information:before,
.icon-important-note:before,
.icon-three-dots-symbol:before,
.icon-055:before,
.icon-045:before,
.icon-news-o:before,
.icon-time:before,
.icon-clock-o:before,
.icon-e-mail:before,
.icon-location-marker:before,
.icon-phone:before,
.icon-link-o:before,
.icon-meeting:before,
.icon-meeting-2:before,
.icon-atmospheric-pressure-2:before,
.icon-task-completed-in-time:before,
.icon-task-completed-in-time-2:before,
.icon-star-o:before,
.icon-in-progress:before,
.icon-close-button:before,
.icon-agreement:before,
.icon-clipboard-checklist:before,
.icon-alert:before,
.icon-door-opened:before,
.icon-065:before,
.icon-064:before,
.icon-licence:before,
.icon-permanent-job:before,
.icon-task:before,
.icon-074:before,
.icon-approval:before,
.icon-handshake:before,
.icon-task-planning:before,
.icon-process:before,
.icon-easy:before,
.icon-057:before,
.icon-coronavirus:before,
.icon-forward-button:before,
.icon-happy:before,
.icon-sad:before,
.icon-star-circle:before,
.icon-load-template:before,
.icon-clock:before,
.icon-training:before,
.icon-068:before,
.icon-069:before,
.icon-067:before,
.icon-070:before,
.icon-071:before,
.icon-072:before,
.icon-073:before,
.icon-view-more:before,
.icon-installing-updates:before,
.icon-plus-2:before,
.icon-pdf:before,
.icon-xls:before,
.icon-word:before,
.icon-cooling:before,
.icon-back-to:before,
.icon-clock-alert:before,
.icon-low-risk:before,
.icon-brake-warning:before,
.icon-high-risk:before,
.icon-checkmark:before,
.icon-next-page:before,
.icon-50-percent:before,
.icon-075:before,
.icon-076:before,
.icon,
[data-icon]:before {
    display: inline-block;
    font-family: "KUBA icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}
.icon-profile:before { content: "\f100";}
.icon-view-detail:before { content: "\f101";}
.icon-expand-arrow:before { content: "\f102";}
.icon-edit-content:before { content: "\f103";}
.icon-046:before { content: "\f103";}
.icon-020:before { content: "\f104";}  
.icon-021:before { content: "\f105";}
.icon-product:before { content: "\f106";}
.icon-014:before { content: "\f107";}
.icon-filing-cabinet:before { content: "\f108";}
.icon-019:before { content: "\f109";}
.icon-018:before { content: "\f10a";}
.icon-016:before { content: "\f10b";}
.icon-contract-job:before { content: "\f10c";}
.icon-013:before { content: "\f10d";}
.icon-details-pane:before { content: "\f10e";}
.icon-truck:before { content: "\f10f";}
.icon-activity-log:before { content: "\f110";}
.icon-page-overview-2:before { content: "\f111";}
.icon-page:before { content: "\f112";}
.icon-012:before { content: "\f113";}
.icon-edit:before { content: "\f114";}
.icon-view-content:before { content: "\f115";}
.icon-checkmark:before { content: "\f116";}
.icon-reset:before { content: "\f117";}
.icon-005:before { content: "\f118";}
.icon-online-support:before { content: "\f119";}
.icon-message:before { content: "\f11a";}
.icon-help:before { content: "\f11b";}
.icon-063:before { content: "\f11b";}
.icon-document:before { content: "\f11c";}
.icon-documents:before { content: "\f11d";}
.icon-002:before { content: "\f11e";}
.icon-040:before { content: "\f11f";}
.icon-high-priority:before { content: "\f120";}
.icon-003:before { content: "\f121";}
.icon-search:before { content: "\f122";}
.icon-marker:before { content: "\f123";}
.icon-menu-2:before { content: "\f124";}
.icon-forward:before { content: "\f125";}
.icon-008:before { content: "\f126";}
.icon-notification:before { content: "\f127";}
.icon-006:before { content: "\f128";}
.icon-007:before { content: "\f129";}
.icon-star:before { content: "\f12a";}
.icon-new-deadline:before { content: "\f12a";}
.icon-038:before { content: "\f12b";}
.icon-010:before { content: "\f12c";}
.icon-011:before { content: "\f12d";}
.icon-009:before { content: "\f12e";}
.icon-back:before { content: "\f12f";}
.icon-015:before { content: "\f130";}
.icon-032:before { content: "\f131";}
.icon-033:before { content: "\f132";}
.icon-034:before { content: "\f133";}
.icon-trash:before { content: "\f134";}
.icon-035:before { content: "\f134";}
.icon-025:before { content: "\f135";}
.icon-004:before { content: "\f136";}
.icon-036:before { content: "\f137";}
.icon-toggle-off:before { content: "\f138";}
.icon-toggle-on:before { content: "\f139";}
.icon-037:before { content: "\f13a";}
.icon-026:before { content: "\f13b";}
.icon-near-deadline:before { content: "\f13c";}
.icon-030:before { content: "\f13c";}
.icon-far-deadline:before { content: "\f13d";}
.icon-over-deadline:before { content: "\f13e";}
.icon-039:before { content: "\f13f";}
.icon-sort-down:before { content: "\f140";}
.icon-high-importance:before { content: "\f141";}
.icon-027:before { content: "\f142";}
.icon-timer:before { content: "\f143";}
.icon-028:before { content: "\f144";}
.icon-029:before { content: "\f145";}
.icon-bar-chart:before { content: "\f146";}
.icon-area-chart:before { content: "\f147";}
.icon-collapse-arrow:before { content: "\f148";}
.icon-multiply:before { content: "\f149";}
.icon-planner:before { content: "\f14a";}
.icon-picture:before { content: "\f14b";}
.icon-play:before { content: "\f14c";}
.icon-circled-play:before { content: "\f14d";}
.icon-info:before { content: "\f14e";}
.icon-049:before { content: "\f14f";}
.icon-thermometer-automation:before { content: "\f150";}
.icon-external-link-squared:before { content: "\f15b";}
.icon-copy-to-clipboard:before { content: "\f15c";}
.icon-print:before { content: "\f15d";}
.icon-paste:before { content: "\f15e";}
.icon-001:before { content: "\f15f";}
.icon-pencil-tip:before { content: "\f160";}
.icon-delete:before { content: "\f161";}
.icon-clear-deadline:before { content: "\f162";}
.icon-preview-pane:before { content: "\f163";}
.icon-folder:before { content: "\f164";}
.icon-open:before { content: "\f167";}
.icon-file:before { content: "\f168";}
.icon-filter:before { content: "\f169";}
.icon-send-file:before { content: "\f16a";}
.icon-checklist:before { content: "\f16b";}
.icon-trash-can:before { content: "\f16c";}
.icon-add-file:before { content: "\f16d";}
.icon-image-file:before { content: "\f16e";}
.icon-video-file:before { content: "\f16f";}
.icon-audio-file:before { content: "\f170";}
.icon-attach:before { content: "\f171";}
.icon-documents-o:before { content: "\f172";}
.icon-document-o:before { content: "\f173";}
.icon-send-file-o:before { content: "\f174";}
.icon-file-o:before { content: "\f176";}
.icon-image-file-o:before { content: "\f178";}
.icon-video-file-o:before { content: "\f179";}
.icon-048:before { content: "\f17a";}
.icon-cancel:before { content: "\f17b";}
.icon-066:before { content: "\f17c";}
.icon-filter-o:before { content: "\f17d";}
.icon-clear-filters:before { content: "\f17e";}
.icon-clear-filters-o:before { content: "\f17f";}
.icon-activity-history:before { content: "\f180";}
.icon-find-user-male:before { content: "\f181";}
.icon-user-rights:before { content: "\f182";}
.icon-plus:before { content: "\f183";}
.icon-front-desk:before { content: "\f184";}
.icon-flow-chart:before { content: "\f185";}
.icon-combo-chart:before { content: "\f186";}
.icon-chart:before { content: "\f187";}
.icon-031:before { content: "\f188";}
.icon-041:before { content: "\f189";}
.icon-042:before { content: "\f18a";}
.icon-017:before { content: "\f18b";}
.icon-food-plate:before { content: "\f18c";}
.icon-044:before { content: "\f18d";}
.icon-suitcase:before { content: "\f18e";}
.icon-047:before { content: "\f18f";}
.icon-light-automation:before { content: "\f190";}
.icon-043:before { content: "\f191";}
.icon-050:before { content: "\f192";}
.icon-051:before { content: "\f195";}
.icon-not-applicable:before { content: "\f196";}
.icon-services:before { content: "\f19b";}
.icon-in:before { content: "\f19c";}
.icon-out:before { content: "\f19d";}
.icon-add:before { content: "\f1a3";}
.icon-checklist-property:before { content: "\f1a4";}
.icon-checklist-property-edit:before { content: "\f1a5";}
.icon-022:before { content: "\f1a6";}
.icon-023:before { content: "\f1a7";}
.icon-024:before { content: "\f1a8";}
.icon-download-from-cloud:before { content: "\f1a9";}
.icon-download-from-the-cloud:before { content: "\f1aa";}
.icon-download:before { content: "\f1ab";}
.icon-external-link:before { content: "\f1ac";}
.icon-external-link-2:before { content: "\f1ad";}
.icon-info-o:before { content: "\f1ae";}
.icon-053:before { content: "\f1af";}
.icon-054:before { content: "\f1b0";}
.icon-056:before { content: "\f1b1";}
.icon-052:before { content: "\f1b2";}
.icon-059:before { content: "\f1b3";}
.icon-male-user:before { content: "\f1b5";}
.icon-shopping-cart:before { content: "\f1b6";}
.icon-information:before { content: "\f1b7";}
.icon-important-note:before { content: "\f1b8";}
.icon-three-dots-symbol:before { content: "\f1b9";}
.icon-055:before { content: "\f1bc";}
.icon-045:before { content: "\f1bd";}
.icon-news-o:before { content: "\f1be";}
.icon-time:before { content: "\f1bf";}
.icon-clock-o:before { content: "\f1c0";}
.icon-e-mail:before { content: "\f1c1";}
.icon-location-marker:before { content: "\f1c2";}
.icon-phone:before { content: "\f1c3";}
.icon-link-o:before { content: "\f1c4";}
.icon-meeting:before { content: "\f1c5";}
.icon-meeting-2:before { content: "\f1c6";}
.icon-atmospheric-pressure-2:before { content: "\f1c9";}
.icon-task-completed-in-time:before { content: "\f1ca";}
.icon-task-completed-in-time-2:before { content: "\f1cb";}
.icon-star-o:before { content: "\f1cc";}
.icon-in-progress:before { content: "\f1cd";}
.icon-close-button:before { content: "\f1cf";}
.icon-agreement:before { content: "\f1d1";}
.icon-clipboard-checklist:before { content: "\f1d2";}
.icon-alert:before { content: "\f1d3";}
.icon-door-opened:before { content: "\f1d4";}
.icon-065:before { content: "\f1d5";}
.icon-064:before { content: "\f1d6";}
.icon-licence:before { content: "\f1d7";}
.icon-permanent-job:before { content: "\f1d8";}
.icon-task:before { content: "\f1d9";}
.icon-074:before { content: "\f1da";}
.icon-approval:before { content: "\f1db";}
.icon-handshake:before { content: "\f1dc";}
.icon-task-planning:before { content: "\f1dd";}
.icon-process:before { content: "\f1de";}
.icon-easy:before { content: "\f1df";}
.icon-057:before { content: "\f1e1";}
.icon-coronavirus:before { content: "\f1e3";}
.icon-forward-button:before { content: "\f1e9";}
.icon-happy:before { content: "\f1ea";}
.icon-sad:before { content: "\f1eb";}
.icon-star-circle:before { content: "\f1ec";}
.icon-load-template:before { content: "\f1ed";}
.icon-clock:before { content: "\f1ee";}
.icon-training:before { content: "\f1ef";}
.icon-068:before { content: "\f1f0";}
.icon-069:before { content: "\f1f2";}
.icon-067:before { content: "\f1f3";}
.icon-070:before { content: "\f1f4";}
.icon-071:before { content: "\f1f5";}
.icon-072:before { content: "\f1f6";}
.icon-073:before { content: "\f1f7";}
.icon-view-more:before { content: "\f1f8";}
.icon-installing-updates:before { content: "\f1f9";}
.icon-plus-2:before { content: "\f1fa";}
.icon-pdf:before { content: "\f1fd";}
.icon-xls:before { content: "\f1fe";}
.icon-word:before { content: "\f200";}
.icon-cooling:before { content: "\f201";}
.icon-back-to:before { content: "\f203";}
.icon-clock-alert:before { content: "\f204";}
.icon-low-risk:before { content: "\f205";}
.icon-brake-warning:before { content: "\f26";}
.icon-high-risk:before { content: "\f207";}
.icon-checkmark:before { content: "\f208";}
.icon-next-page:before { content: "\f209";}
.icon-50-percent:before { content: "\f20a";}
.icon-075:before { content: "\f20c";}
.icon-076:before { content: "\f20d";}
  